import './CampaignLeads.css';

import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import axios from 'axios';
import * as React from 'react';

import LeadsGrid from './CampaignLeadsPreview';
import CampaignLeadsSidePanel from './CampaignLeadsSidePanel';
import CampaignLeadsDialog from './CampaignLeadsUploadDialog';


interface CampaignLeadsProps {
    selectedCampaignId: string | null;
}

interface ListItem {
    _id: string;
    name: string;
}

interface FolderItem {
    folder: {
        _id: string;
        name: string;
    };
    lists: {
        _id: string;
        name: string;
    }[];
}

const CampaignLeads: React.FC<CampaignLeadsProps> = ({ selectedCampaignId }) => {
    const [foldersWithLists, setFoldersWithLists] = React.useState<FolderItem[]>([]);
    const [listsWithoutFolder, setListsWithoutFolder] = React.useState<ListItem[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedList, setSelectedList] = React.useState<string | null>(null);
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [showCloseConfirmDialog, setShowCloseConfirmDialog] = React.useState(false);


    const fetchAssociatedList = (campaignId: string | null) => {
        if (campaignId) {
            axios.get(`${process.env.REACT_APP_API_URL}/campaign/${campaignId}`)
                .then(response => {
                    const associatedListId = response.data.listId;
                    setSelectedList(associatedListId);
                })
                .catch(error => {
                    console.error('Error fetching associated list:', error);
                });
        }
    };

    const fetchData = () => {
        setLoading(true); // Set loading to true when fetching data
        setShowSkeleton(true); // Always show the skeleton initially

        axios.get(`${process.env.REACT_APP_API_URL}/leads/folders-with-lists`)
            .then(response => {
                setFoldersWithLists(response.data.foldersWithLists);
                setListsWithoutFolder(response.data.listsWithoutFolder);

                // Use setTimeout to hide the skeleton after 2 seconds
                setShowSkeleton(false);

                setLoading(false); // Set loading to false when data is fetched
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);

                // Use setTimeout to hide the skeleton after 2 seconds even in case of an error
                setShowSkeleton(false);


                setLoading(false); // Set loading to false in case of an error
            });
    };


    React.useEffect(() => {
        fetchData();
        fetchAssociatedList(selectedCampaignId);
    }, [selectedCampaignId]);




    const openDialog = () => {
        setIsDialogOpen(true);
    };




    return (
        <>
            <IconButton onClick={openDialog} sx={{ mx: 'auto' }}>
                <CloudUploadSharpIcon />
            </IconButton>

            <IconButton onClick={fetchData} sx={{ mx: 'auto' }}>
                <RefreshIcon />
            </IconButton>
            <Grid container spacing={2}>
                <CampaignLeadsSidePanel selectedCampaignId={selectedCampaignId} selectedList={selectedList} setSelectedList={setSelectedList} />

                <Grid item xs={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {selectedList ? (
                        <LeadsGrid selectedListId={selectedList} />
                    ) : (
                        <Button color='info' sx={{ width: "60%" }} onClick={openDialog} size='large'>
                            <CloudUploadSharpIcon color='info' sx={{ fontSize: "240px", border: "dashed 5px", padding: "50px", width: "100%" }} />
                        </Button>
                    )}
                </Grid>

                <CampaignLeadsDialog setShowCloseConfirmDialog={setShowCloseConfirmDialog} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} showCloseConfirmDialog={showCloseConfirmDialog} />
            </Grid>

        </>
    );
}

export default CampaignLeads;
