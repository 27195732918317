import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/src/ui/resizable';
import { Box, Skeleton } from '@mui/material';
import React from 'react';

const MailSkeleton = () => {
    return (
        <Box style={{ height: '87vh', width: '100%' }}>
            <ResizablePanelGroup
                direction="horizontal"
                className="h-full max-h-[800px] items-stretch"
            >
                <ResizablePanel defaultSize={265} minSize={15} maxSize={20}>
                    <Skeleton variant="rectangular" height={52} sx={{ padding: "10px", margin: "10px", borderRadius: "10px" }} />
                    <Skeleton variant="rectangular" height="calc(100% - 52px)" animation="pulse" sx={{ padding: "10px", margin: "10px", borderRadius: "10px" }} />
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel defaultSize={440}>
                    <Skeleton variant="text" height={40} width="30%" sx={{ padding: "10px", margin: "10px", borderRadius: "10px" }} />
                    <Skeleton variant="rectangular" height="calc(100% - 40px)" animation="pulse" sx={{ padding: "10px", margin: "10px", borderRadius: "10px" }} />
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel defaultSize={655}>
                    <Skeleton variant="rectangular" height="100%" animation={false} sx={{ padding: "10px", margin: "10px", borderRadius: "10px" }} />
                </ResizablePanel>
            </ResizablePanelGroup>
        </Box>
    );
};

export default MailSkeleton;
