import React from 'react';
import MailPage from './mail/page';


function MailParnetPage() {


    return (
        <>
            <MailPage />

        </>
    );
}

export default MailParnetPage;
