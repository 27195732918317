// src\app\pages\4-Campaigns\3-CampaignSequence\MoreCampaignSettings.tsx\UpdateTrackingLinks.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query'; // Import useQueryClient

interface UpdateTrackingLinksProps {
    campaignId: string;
}

const UpdateTrackingLinks: React.FC<UpdateTrackingLinksProps> = ({ campaignId }) => {
    const [originalUrls, setOriginalUrls] = useState<string[]>([]);
    const [selectedUrl, setSelectedUrl] = useState<string>('');
    const [newUrl, setNewUrl] = useState<string>('');

    const queryClient = useQueryClient(); // Initialize queryClient

    // Fetch original URLs
    const { data, isLoading, error } = useQuery(
        ['originalUrls', campaignId],
        async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/view/original-urls`, {
                params: { campaignId },
            });
            return response.data.originalUrls;
        },
        {
            enabled: !!campaignId,
            onSuccess: (data) => {
                setOriginalUrls(data);
            },
        }
    );

    // Mutation for updating the URL
    const updateUrlMutation = useMutation(
        async () => {
            await axios.post(`${process.env.REACT_APP_API_URL}/view/update-tracking-link/campaign-link`, {
                campaignId,
                oldOriginalUrl: selectedUrl,
                newOriginalUrl: newUrl,
            });
        },
        {
            onSuccess: () => {
                setSelectedUrl('');
                setNewUrl('');
                // Refetch original URLs
                queryClient.invalidateQueries(['originalUrls', campaignId]);
            },
        }
    );

    const handleUpdateClick = () => {
        updateUrlMutation.mutate();
    };

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography>Error loading original URLs</Typography>;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Update Tracking Links</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    label="Select Original URL"
                    value={selectedUrl}
                    onChange={(e) => setSelectedUrl(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    fullWidth
                >
                    <option value="" />
                    {originalUrls.map((url) => (
                        <option key={url} value={url}>
                            {url}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="New URL"
                    value={newUrl}
                    onChange={(e) => setNewUrl(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateClick}
                    disabled={!selectedUrl || !newUrl || updateUrlMutation.isLoading}
                >
                    Update URL
                </Button>
            </Grid>
        </Grid>
    );
};

export default UpdateTrackingLinks;
