import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import DashboardFamo from '../pages/1-DashBoard/DashboardPage'
import CampaignsPage from '../pages/4-Campaigns/CampaignsPage'
import AdvancedLeadGenPage from '../pages/2-GetLeads/AdvancedSearch/AdvancedLeadGenPage'
import EmailAccountsPage from '../pages/0-Account/EmailAccounts/EmailAccountsPage'
import MailParnetPage from '../pages/5-Inbox/InboxPage'


const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const LocalLeadGenPage = lazy(() => import('../pages/2-GetLeads/LocalLeads/LocalLeadsPage'));
  const LeadsPage = lazy(() => import('../pages/3-CRM/CRMPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path="/dashboard" element={<DashboardFamo />} />
        <Route path="/my-campaigns" element={<CampaignsPage />} />
        <Route path="/advanced-lead-search" element={<AdvancedLeadGenPage />} />
        <Route path="/email-accounts" element={<EmailAccountsPage />} />
        <Route path="/inbox" element={<MailParnetPage />} />

        {/* Lazy Modules */}
        <Route
          path='/local-lead-search'
          element={
            <SuspensedView>
              <LocalLeadGenPage />
            </SuspensedView>
          }
        />
        <Route
          path='/my-leads'
          element={
            <SuspensedView>
              <LeadsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
