// src\app\pages\LeadGenAdvanced\ContentArea.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { AddHomeWorkSharp } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CollectionsBookmarkSharpIcon from '@mui/icons-material/CollectionsBookmarkSharp';
import Face3SharpIcon from '@mui/icons-material/Face3Sharp';
import FaceSharpIcon from '@mui/icons-material/FaceSharp';
import HandymanSharpIcon from '@mui/icons-material/HandymanSharp';
import LibraryAddCheckSharpIcon from '@mui/icons-material/LibraryAddCheckSharp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import OpenInFullSharpIcon from '@mui/icons-material/OpenInFullSharp';
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import { Alert, Badge, Box, CircularProgress, Fab, LinearProgress, Snackbar, Toolbar, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridRowSelectionModel,
    GridRowsProp,
    GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { Suspense, useState } from 'react';

interface Lead {
    id: string;
    full_name: string;
    industry: string;
    job_title: string;
    job_company_name: string;
    linkedin_url: string;
    location_name: string;  // New field
    location_country: string;  // New field
    gender: string | null;  // New field, allowing for null values
    revealed: boolean;
}

interface ContentAreaProps {
    leads: Lead[];
    paginationModel: GridPaginationModel;
    setPaginationModel: (model: GridPaginationModel) => void;
    rowCount: number;
    onSelectionModelChange: (newSelection: string[]) => void;
    selectedLeads: string[];  // Changed from selectedRowIds
    onViewLead: (leadId: string) => void;
    onSelectedLeadsChange: (newSelectedLeads: string[]) => void;
    handleUpdateSearch: () => void;
    handleOpenSaveDialog: () => void;
    toggleDrawer: () => void;
    success: boolean;
    activeSearchId: string;
    activeSearchName: string;
    updateLoading: boolean;
    handleOpenCollectionsDialog: () => void;
    toggleLeadImportDialog: () => void
    isIndustrySelected: boolean;
    isCountrySelected: boolean;
    onIndustryClick: () => void;
    onCountryClick: () => void;
}



const ContentArea: React.FC<ContentAreaProps & { loading: boolean }> = ({
    leads,
    paginationModel,
    setPaginationModel,
    rowCount,
    selectedLeads,
    onSelectionModelChange,
    loading,
    onViewLead,
    onSelectedLeadsChange,
    handleUpdateSearch,
    handleOpenSaveDialog,
    toggleDrawer,
    success,
    activeSearchId,
    activeSearchName,
    updateLoading,
    handleOpenCollectionsDialog,
    toggleLeadImportDialog,
    isIndustrySelected,
    isCountrySelected,
    onIndustryClick,
    onCountryClick,

}) => {
    const [contactInfo, setContactInfo] = useState({});
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [revealedLeads, setRevealedLeads] = useState({});


    const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
        if (Array.isArray(newSelection) && newSelection.length > 1000) {
            setSnackbarOpen(true);
            return;
        }

        // Convert to string array if necessary
        const selectionAsStringArray = newSelection.map(String);

        onSelectionModelChange(selectionAsStringArray);
        onSelectedLeadsChange(selectionAsStringArray);
    };

    // Function to close the snackbar
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // Function to fetch contact info
    const fetchContactInfo = async (leadId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/revealcontactinfo/lead/${leadId}`);
            const data = response.data;
            setContactInfo(prev => ({ ...prev, [leadId]: data }));
            // Mark the lead as revealed
            setRevealedLeads(prev => ({ ...prev, [leadId]: true }));
        } catch (error) {
            console.error("Error fetching contact info:", error);
        }
    };

    // Style for the update button
    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };



    const buttonVariants = {
        hover: {
            scale: 1.1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 10
            }
        },
        tap: {
            scale: 0.95,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 10
            }
        }
    };




    const CustomToolbar = () => {
        return (


            <Toolbar>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Left side buttons */}
                    <Box sx={{ display: 'flex', gap: '8px', alignContent: "center", alignItems: "center" }}>
                        <CustomTooltip title="Manage Columns">
                            <motion.div
                                className="no-global-transition"
                                whileHover="hover" whileTap="tap" variants={buttonVariants}>
                                <GridToolbarColumnsButton sx={{ fontSize: "0px", borderRadius: "10%", transform: "scale(1.2)" }} />
                            </motion.div>
                        </CustomTooltip>

                        {/* Button to manage selection */}
                        <CustomTooltip title="Manage Selection">
                            <motion.div
                                className="no-global-transition"
                                whileHover="hover" whileTap="tap" variants={buttonVariants}>

                                <Badge
                                    badgeContent={selectedLeads.length}
                                    color="secondary"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            color: 'var(--bs-dark-light)', // Your desired text color
                                            fontSize: "15px",
                                        }
                                    }}
                                >
                                    <IconButton onClick={toggleDrawer} color="primary">
                                        <LibraryAddCheckSharpIcon sx={{ fontSize: "25px" }} />
                                    </IconButton>
                                </Badge>
                            </motion.div>
                        </CustomTooltip>

                        {/* Button to open saved searches */}
                        <CustomTooltip title="My Searches">
                            <motion.div
                                className="no-global-transition"
                                whileHover="hover" whileTap="tap" variants={buttonVariants}>
                                <IconButton onClick={handleOpenSaveDialog} color="primary" sx={{ ml: 2 }}>
                                    <PersonSearchSharpIcon sx={{ fontSize: "25px" }} />
                                </IconButton>
                            </motion.div>
                        </CustomTooltip>

                        <CustomTooltip title="My Leads">
                            <IconButton onClick={handleOpenCollectionsDialog} color="primary">
                                <CollectionsBookmarkSharpIcon />
                            </IconButton>
                        </CustomTooltip>


                    </Box>

                    <CustomTooltip title="Import Leads">
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Fab
                                aria-label="import"
                                color="primary"
                                onClick={toggleLeadImportDialog}
                            >
                                <AddHomeWorkSharp sx={{ color: "white", fontSize: "35px" }} />
                            </Fab>
                        </Box>
                    </CustomTooltip>

                    {/* Right side button */}
                    {activeSearchId && activeSearchName && (
                        <CustomTooltip title="Save Changes">
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Fab
                                    aria-label="save"
                                    color="primary"
                                    sx={buttonSx}
                                    onClick={handleUpdateSearch}
                                >
                                    {success ? <CheckIcon sx={{ fontSize: "35px" }} /> : <SaveSharpIcon sx={{ fontSize: "35px" }} />}
                                </Fab>
                                {updateLoading && (
                                    <CircularProgress
                                        size={68}
                                        color='secondary'
                                        sx={{
                                            position: 'absolute',
                                            top: -6,
                                            left: -6,
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                            </Box>
                        </CustomTooltip>

                    )}
                </Box>
            </Toolbar >
        );
    };

    const columns: GridColDef[] = [
        { field: 'full_name', headerName: 'Full Name', width: 200, sortable: false, filterable: false, groupable: false, aggregable: false, hideable: false, pinnable: false },
        { field: 'industry', headerName: 'Industry', width: 150, sortable: false, filterable: false, groupable: false, aggregable: false, hideable: false, pinnable: false },

        {
            field: 'location_country',
            headerName: 'Country',
            width: 150,
            sortable: true,
            filterable: true,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                if (params.value === 'male') {
                    return <FaceSharpIcon style={{ color: '#7BB0FF', fontSize: '30px' }} />;
                } else if (params.value === 'female') {
                    return <Face3SharpIcon style={{ color: '#E98FE5', fontSize: '30px' }} />;
                } else {
                    // Handle other cases, like null or undefined
                    return;
                }
            },
        },
        { field: 'job_title', headerName: 'Job Title', width: 180, sortable: false, filterable: false, groupable: false, aggregable: false, hideable: false, pinnable: false },
        { field: 'job_company_name', headerName: 'Company Name', width: 200, sortable: false, filterable: false, groupable: false, aggregable: false, hideable: false, pinnable: false },
        {
            field: 'location_name',
            headerName: 'Location',
            width: 200,
            sortable: true,
            filterable: true,
        },
        {
            field: 'linkedin_url',
            headerName: '',
            width: 70,
            sortable: false,
            filterable: false,
            groupable: false,
            aggregable: false,
            hideable: false,
            pinnable: false,
            renderCell: (params) => {
                // Check if the URL starts with "https://" or "http://"
                const url = params.value.startsWith('http://') || params.value.startsWith('https://')
                    ? params.value // Use the provided URL as is
                    : `https://www.${params.value}`; // Add "https://www." if missing

                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon color="secondary" style={{ fontSize: '30px' }} />
                    </a>
                );
            },
        },



        {
            field: 'view',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            groupable: false,
            aggregable: false,
            hideable: false,
            pinnable: false,
            renderCell: (params) => (
                <IconButton
                    aria-label="view"
                    onClick={(event) => {
                        event.stopPropagation();
                        onViewLead(params.row.id);  // Pass the lead ID to the parent component
                    }}
                >
                    <OpenInFullSharpIcon color='secondary' style={{ fontSize: '30px' }} />
                </IconButton>
            ),

        },

        // add to collectino button
        // {
        //     field: 'contactInfo',
        //     headerName: '',
        //     width: 100,
        //     sortable: false,
        //     filterable: false,
        //     renderCell: (params) => {
        //         if (revealedLeads[params.row.id]) {
        //             // Show success button if lead info is revealed
        //             return (
        //                 <Button
        //                     variant="contained"
        //                     color="secondary"
        //                 >
        //                     <CheckCircleSharpIcon sx={{ color: "#fff", fontSize: "25px" }} />
        //                 </Button>
        //             );
        //         }

        //         return (
        //             <CustomTooltip title="Add to Default Collection">
        //                 <Button
        //                     onClick={(event) => {
        //                         event.stopPropagation();
        //                         fetchContactInfo(params.row.id);
        //                     }}
        //                     color='primary'
        //                     variant='contained'
        //                 >
        //                     <AddBoxSharpIcon sx={{ color: "#fff", fontSize: "25px" }} />
        //                 </Button>
        //             </CustomTooltip>
        //         );
        //     },
        // },

    ];



    const rows: GridRowsProp = leads.map(lead => ({
        id: lead.id,
        full_name: capitalizeWords(lead.full_name),
        industry: capitalizeWords(lead.industry),
        job_title: capitalizeWords(lead.job_title),
        job_company_name: capitalizeWords(lead.job_company_name),
        linkedin_url: capitalizeWords(lead.linkedin_url),
        location_name: capitalizeWords(lead.location_name),
        location_country: capitalizeWords(lead.location_country),
        gender: lead.gender,
    }));

    const CustomLinearProgress = () => {
        const customStyle = { height: '20px' }; // Set your desired height here
        return <LinearProgress variant="indeterminate" style={customStyle} color='primary' />;
    };

    function capitalizeWords(str) {
        // Check if str is null or undefined
        if (!str) return str;

        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }


    console.log(leads);

    return (
        <div style={{ height: 600, width: '100%' }}>
            {(!isIndustrySelected || !isCountrySelected) ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    {!isIndustrySelected && (
                        <Tooltip title="Select Industry">
                            <IconButton onClick={onIndustryClick}>
                                <HandymanSharpIcon color="info" style={{ fontSize: '80px' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!isCountrySelected && (
                        <Tooltip title="Select Country">
                            <IconButton onClick={onCountryClick}>
                                <PublicSharpIcon color="info" style={{ fontSize: '80px' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Typography variant="h6" align="center" mt={2}>
                        Please select both an industry and a location from the filters to view leads.
                    </Typography>
                </Box>
            ) : (


                <Suspense fallback={<CircularProgress />}>

                    <DataGridPremium
                        className="myGrid"
                        rows={rows}
                        columns={columns}
                        rowCount={rowCount}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        checkboxSelection
                        pinnedColumns={{ right: ['view', "contactInfo"], left: ['__check__', 'full_name'] }}
                        rowSelectionModel={selectedLeads}  // Changed from selectedRowIds
                        onRowSelectionModelChange={handleSelectionModelChange}
                        onPaginationModelChange={setPaginationModel}
                        loading={loading}
                        slots={{
                            loadingOverlay: CustomLinearProgress,
                            toolbar: CustomToolbar,

                        }}
                        keepNonExistentRowsSelected
                        sx={{ border: "0px", height: "730px" }}
                    />

                </Suspense>

            )}
            <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                    You can select a maximum of 100 leads.
                </Alert>
            </Snackbar>

        </div>
    );
}

export default ContentArea;
