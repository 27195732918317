// src\app\App.tsx
import React, { useState, useEffect } from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from './modules/auth';
import { ThemeModeProvider, ThemeModeType, systemMode, getInitialThemeMode } from '../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from '../MaterialTheme'; // Adjust the path accordingly
import { Scrollbars } from 'react-custom-scrollbars';
import { GlassEffectProvider } from '../_metronic/layout/components/header/GlassEffectContext';
import LogoutWhenRefreshTokenExpires from './modules/auth/Logout';
import ConnectionLostTracking from '../_metronic/layout/components/header/ConnectionLostTracking';

import axios from 'axios';



const App = () => {
  const initialMode = getInitialThemeMode(); // Ensure this is the shared source of truth
  const [mode, setMode] = useState<ThemeModeType>(initialMode);
  axios.defaults.withCredentials = true;

  useEffect(() => {
    handleThemeChange(initialMode); // Apply initial theme mode on app start
  }, [initialMode]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     HotJar                                     ||
  // ! ||--------------------------------------------------------------------------------||

  const siteId = 4977308;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);



  // Function to toggle the Material-UI theme
  const handleThemeChange = (newMode: ThemeModeType) => {
    setMode(newMode === 'system' ? systemMode : newMode); // Handle 'system' mode
  };

  const currentTheme = mode === 'light' ? lightTheme : darkTheme;


  // Customize scrollbar color
  const renderThumbVertical = ({ style, ...props }: any) => {
    const thumbStyle = {
      backgroundColor: currentTheme.palette.primary.main, // Adjust the color as needed
      width: "20px"
    };

    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <>

      <ThemeProvider theme={currentTheme}>

        <ThemeModeProvider onThemeChange={handleThemeChange}> {/* Pass the callback */}
          <ConnectionLostTracking />
          <GlassEffectProvider>
            {/* <CssBaseline /> */}
            <Suspense fallback={<LayoutSplashScreen />}>
              <I18nProvider>
                <LayoutProvider>
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={500}
                    thumbMinSize={30}
                    universal={true}
                    renderThumbVertical={renderThumbVertical} // Apply custom rendering
                  >

                    <AuthInit>
                      <LogoutWhenRefreshTokenExpires />
                      <Outlet />
                      <MasterInit />
                    </AuthInit>
                  </Scrollbars>

                </LayoutProvider>
              </I18nProvider>
            </Suspense>
          </GlassEffectProvider>
        </ThemeModeProvider>
      </ThemeProvider>
    </>
  );
};

export { App };
