// src\app\pages\5-Inbox\mail\components\mail-display.tsx
import { Paper, Typography } from '@mui/material';
import React from 'react';

import { Mail } from '../data';
import { MailContent } from './MailDetails';
import SMSConversationDisplay from './SMSConversationDisplay';
import { TagsDropdown } from './TagsDropdown';

interface MailDisplayProps {
  items: Mail | null;
  selectedAccountId: string;
  selectedAccount: any;
}

const MailDisplay: React.FC<MailDisplayProps> = ({ items: items, selectedAccountId, selectedAccount }) => {
  if (!items) {
    return <Typography className="p-8 text-center text-muted-foreground">No message selected</Typography>;
  }

  // Determine if the mail item is an SMS conversation
  const isSMSConversation = items.number1 !== undefined;

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center p-2">
        <TagsDropdown messageId={isSMSConversation ? items.id : items.messageId} existingTags={items.tags} selectedAccount={selectedAccount} />
      </div>
      {!isSMSConversation && (
        <Paper style={{ overflow: "auto" }} >

          <MailContent item={items} from={selectedAccountId} />
        </Paper>
      )}
      {isSMSConversation &&

        <Paper style={{ overflow: "auto" }} >
          <SMSConversationDisplay from={selectedAccount} conversationId={items.id!} />
        </Paper>

      }
    </div>
  );
};

export default MailDisplay;
