// src\app\pages\5-Inbox\mail\components\mail.tsx
import { cn } from '@/src/lib/utils';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/src/ui/resizable';
import { Separator } from '@/src/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/src/ui/tabs';
import { TooltipProvider } from '@/src/ui/tooltip';
import { SearchSharp } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import * as React from 'react';

import { Mail } from '../data';
import { useMail } from '../use-mail';
import { AccountSwitcher } from './account-switcher';
import MailDisplay from './mail-display';
import { MailList } from './mail-list';
import { Nav } from './nav';

interface Account {
  label: string;
  email: string;
  icon: React.ReactNode;
  emailAccountId: string;
  type: string; // Ensure this is included
}


interface MailProps {
  accounts: Account[];
  mails: Mail[];
  defaultLayout: number[] | undefined;
  defaultCollapsed?: boolean;
  navCollapsedSize: number;
  onAccountChange: (accountId: string, accountType: string) => void; // Adjusted to accept two arguments
  selectedAccountId: string;
  selectedAccount: any;
}

export function MailComponent({
  accounts,
  mails,
  defaultLayout = [265, 440, 655],
  defaultCollapsed = false,
  navCollapsedSize,
  onAccountChange,
  selectedAccountId,
  selectedAccount

}: MailProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)
  const [filter, setFilter] = React.useState<string>("all");
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const [mail] = useMail()

  // Handler for when a filter is selected in Nav
  const handleSelectFilter = (selectedFilter: string) => {
    setFilter(selectedFilter);
  };
  // Filter mails based on the current filter
  const filteredMails = mails.filter(mail => {
    // Filter by selected filter (e.g., "all", "unread")
    const filterCondition = filter === "all" ? true : mail.tags.some(tag => tag.name === filter);

    // Filter by search query (case-insensitive)
    const searchCondition = searchQuery.length === 0 || [mail.name, mail.subject, mail.text].some(
      (field) => field.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filterCondition && searchCondition;
  });


  return (
    <Box style={{ height: '87vh', width: '100%' }}>



      <TooltipProvider delayDuration={0}>
        <ResizablePanelGroup
          direction="horizontal"
          onLayout={(sizes: number[]) => {
            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
              sizes
            )}`
          }}
          className="h-full max-h-[800px] items-stretch"
        >
          <ResizablePanel
            defaultSize={defaultLayout[0]}
            collapsedSize={navCollapsedSize}
            collapsible={true}
            minSize={15}
            maxSize={20}
            //@ts-ignore
            onCollapse={(collapsed) => {
              setIsCollapsed(collapsed)
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                collapsed
              )}`
            }}
            className={cn(isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out")}
          >
            <div className={cn("flex h-[52px] items-center justify-center", isCollapsed ? 'h-[52px]' : 'px-2')}>
              <AccountSwitcher
                isCollapsed={isCollapsed}
                accounts={accounts}
                onAccountSelected={onAccountChange}
                selectedAccountId={selectedAccountId}

              />
            </div>
            <Separator />
            <Nav isCollapsed={isCollapsed} onSelectFilter={handleSelectFilter} mails={mails} />


          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
            <Tabs defaultValue="all">
              <div className="flex items-center px-4 py-2">
                <h1 className="text-xl font-bold">Inbox</h1>
                <TabsList className="ml-auto">
                  <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">All mail</TabsTrigger>
                  <TabsTrigger value="unread" className="text-zinc-600 dark:text-zinc-200">Unread</TabsTrigger>
                </TabsList>
              </div>
              <Separator />
              <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <form>
                  <div className="relative">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchSharp />
                          </InputAdornment>
                        ),
                      }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      fullWidth placeholder="Search" className="pl-8" />

                  </div>
                </form>
              </div>
              <TabsContent value="all" className="m-0">
                <MailList items={filteredMails} />
              </TabsContent>
              <TabsContent value="unread" className="m-0">
                <MailList items={filteredMails.filter((item) => !item.read)} />
              </TabsContent>
            </Tabs>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={defaultLayout[2]}>
            <MailDisplay
              items={mails.find((item) => item.id === mail.selected) || null}
              selectedAccountId={selectedAccountId}
              selectedAccount={selectedAccount}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </TooltipProvider>
    </Box>
  )
}
