// src\app\pages\5-Inbox\mail\components\nav.tsx
import {
  ExpandLessSharp,
  ExpandMoreSharp,
  LabelSharp,
  MailOutlineSharp,
  MeetingRoomSharp,
  StyleSharp,
  ThumbDownSharp,
  ThumbUpSharp,
} from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useMemo, useState } from 'react';

interface NavProps {
  isCollapsed: boolean;
  onSelectFilter: (filter: string) => void;
  mails: any[];
}

export function Nav({ isCollapsed, onSelectFilter, mails }: NavProps) {
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('all'); // default to 'all'

  const uniqueTags = useMemo(() => {
    const tags = mails.flatMap(mail => mail.tags || []);
    const unique = Array.from(new Set(tags.map(tag => tag.name))).map(name => {
      return {
        name,
        color: tags.find(tag => tag.name === name)?.color,
      };
    });
    return unique;
  }, [mails]);


  const handleClick = () => {
    setOpen(!open);
  };

  // Function to handle selection and notify parent component
  const handleSelectFilter = (filter: string) => () => {
    onSelectFilter(filter);
    setSelectedFilter(filter); // Set the active filter state
  };


  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
      data-collapsed={isCollapsed}
    >
      {/* Static navigation items with conditional styling for active selection */}
      <ListItemButton
        onClick={handleSelectFilter("all")}
        selected={selectedFilter === 'all'}
        sx={{ bgcolor: selectedFilter === 'all' ? 'primary.light' : 'inherit' }}
      >
        <ListItemIcon>
          <MailOutlineSharp color='secondary' />
        </ListItemIcon>
        <ListItemText primary="All Replies" />
      </ListItemButton>

      <ListItemButton
        onClick={handleSelectFilter("booked")}
        selected={selectedFilter === 'booked'}
        sx={{ bgcolor: selectedFilter === 'booked' ? 'primary.light' : 'inherit' }}
      >
        <ListItemIcon>
          <MeetingRoomSharp color='secondary' />
        </ListItemIcon>
        <ListItemText primary="Meetings" />
      </ListItemButton>

      <ListItemButton
        onClick={handleSelectFilter("interested")}
        selected={selectedFilter === 'interested'}
        sx={{ bgcolor: selectedFilter === 'interested' ? 'primary.light' : 'inherit' }}
      >
        <ListItemIcon>
          <ThumbUpSharp color='secondary' />
        </ListItemIcon>
        <ListItemText primary="Interested" />
      </ListItemButton>

      <ListItemButton
        onClick={handleSelectFilter("not interested")}
        selected={selectedFilter === 'not interested'}
        sx={{ bgcolor: selectedFilter === 'not interested' ? 'primary.light' : 'inherit' }}
      >
        <ListItemIcon>
          <ThumbDownSharp color='secondary' />
        </ListItemIcon>
        <ListItemText primary="Not Interested" />
      </ListItemButton>

      {/* Expandable "Other" section */}
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <StyleSharp color='secondary' />
        </ListItemIcon>
        <ListItemText primary="Other" />
        {open ? <ExpandLessSharp /> : <ExpandMoreSharp />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {uniqueTags.map((tag) => (
            <ListItemButton
              onClick={handleSelectFilter(tag.name)}
              key={tag.name}
              selected={selectedFilter === tag.name}
              sx={{ bgcolor: selectedFilter === tag.name ? 'primary.light' : 'inherit', pl: 4 }}
            >
              <ListItemIcon>
                <LabelSharp style={{ color: tag.color }} />
              </ListItemIcon>
              <ListItemText primary={tag.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </List>
  );

}
