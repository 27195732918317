import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import Groups3SharpIcon from '@mui/icons-material/Groups3Sharp';
import { motion } from 'framer-motion';
import QuestionAnswerSharpIcon from '@mui/icons-material/QuestionAnswerSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { Drawer, useMediaQuery } from '@mui/material';
import { WidgetsSharp } from '@mui/icons-material';

export function MenuInner() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperLabel, setPopperLabel] = useState('');
  const [open, setOpen] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const isSubmenuOpen = Boolean(submenuAnchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 991px)');

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };

  const handleSubmenuOpen = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleMouseEnter = (event, label) => {
    setAnchorEl(event.currentTarget);
    setPopperLabel(label);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const isPathForLeads = (currentPath) => {
    const leadsPaths = ["/my-leads", "/local-lead-search", "/advanced-lead-search"];
    return leadsPaths.includes(currentPath);
  };

  const getLeadsIconColor = () => {
    return isPathForLeads(location.pathname) ? "#F79010" : "var(--bs-primary)";
  };

  const getIconColor = (path) => {
    return location.pathname === path ? "#F79010" : "var(--bs-primary)";
  };

  const fadeInOut = {
    initial: { opacity: 0, scale: 0.95, transition: { duration: 0.2 } },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, scale: 0.95, transition: { duration: 0.2 } }
  };

  const elasticVariants = {
    initial: { scale: 0.8 },
    animate: { scale: 1.1, transition: { type: 'spring', stiffness: 200, damping: 10 } },
    exit: { scale: 0.8, transition: { duration: 1 } }
  };

  return (
    <>
      {!isMobile && (
        <>
          <IconButton component={Link} size='large' to="/dashboard" color="primary"
            onMouseEnter={(e) => handleMouseEnter(e, 'Dashboard')}
            onMouseLeave={handleMouseLeave}>
            <motion.div
              className="no-global-transition"
              whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
              whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
            >
              <HomeSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/dashboard") }} />
            </motion.div>
          </IconButton>

          <IconButton component={Link} to="/email-accounts" color="primary"
            onMouseEnter={(e) => handleMouseEnter(e, 'Connections')}
            onMouseLeave={handleMouseLeave}>
            <motion.div
              className="no-global-transition"
              whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
              whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
            >
              <SettingsSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/email-accounts") }} />
            </motion.div>
          </IconButton>



          <IconButton component={Link} to="/my-campaigns" color="primary"
            onMouseEnter={(e) => handleMouseEnter(e, 'Campaigns')}
            onMouseLeave={handleMouseLeave}>
            <motion.div
              className="no-global-transition"
              whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
              whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
            >
              <SendSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/my-campaigns") }} />
            </motion.div>
          </IconButton>

          <IconButton component={Link} to="/inbox" color="primary"
            onMouseEnter={(e) => handleMouseEnter(e, 'Inbox')}
            onMouseLeave={handleMouseLeave}>
            <motion.div
              className="no-global-transition"
              whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
              whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
            >
              <QuestionAnswerSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/inbox") }} />
            </motion.div>
          </IconButton>

          <IconButton color="primary"
            onMouseEnter={(e) => handleMouseEnter(e, 'Leads')}
            onMouseLeave={handleMouseLeave}
            onClick={handleSubmenuOpen}>
            <motion.div
              className="no-global-transition"
              whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
              whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
            >
              <Groups3SharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getLeadsIconColor() }} />
            </motion.div>
          </IconButton>
        </>
      )}

      <Popper id="simple-popper" open={open} anchorEl={anchorEl}>
        <motion.div
          className="no-global-transition"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elasticVariants}
        >
          <div className="custom-popper" style={{ fontSize: "13px", opacity: "0.7" }}>{popperLabel}</div>
        </motion.div>
      </Popper>

      <Menu
        anchorEl={submenuAnchorEl}
        open={isSubmenuOpen}
        onClose={handleSubmenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem component={Link} to="/my-leads" onClick={handleSubmenuClose}>
          My Lists
        </MenuItem>
        {/* <MenuItem>
          Local Leads (SOON)
        </MenuItem> */}
        <MenuItem component={Link} to="/advanced-lead-search" onClick={handleSubmenuClose}>
          Advanced Lead Search
        </MenuItem>
      </Menu>

      {isMobile && (
        <>
          <IconButton
            color="primary"
            onClick={() => setDrawerOpen(true)}
            size="large"
            sx={{ width: '100%', justifyContent: 'flex-start' }}
          >
            <WidgetsSharp color='info' sx={{ fontSize: "35px" }} />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <IconButton component={Link} size='large' to="/dashboard" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Dashboard')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <HomeSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/dashboard") }} />
              </motion.div>
              Dashboard
            </IconButton>

            <IconButton component={Link} to="/email-accounts" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Connections')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SettingsSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/email-accounts") }} />
              </motion.div>
              Connections
            </IconButton>


            <IconButton component={Link} to="/my-campaigns" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Campaigns')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SendSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/my-campaigns") }} />
              </motion.div>
              My Campaigns
            </IconButton>

            <IconButton component={Link} to="/inbox" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Inbox')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <QuestionAnswerSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/inbox") }} />
              </motion.div>
              Replies
            </IconButton>

            <IconButton color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Leads')}
              onMouseLeave={handleMouseLeave}
              onClick={handleSubmenuOpen}>
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <Groups3SharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getLeadsIconColor() }} />
              </motion.div>
            </IconButton>
          </Drawer>
        </>
      )}
    </>
  );
}
