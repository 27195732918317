//src\app\pages\LeadGenAdvanced\SelectedLeadsDisplay.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import BackspaceSharpIcon from '@mui/icons-material/BackspaceSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface Collection {
    _id: string;
    collectionName: string;
}

interface Lead {
    id: string;
    full_name: string;
    industry: string;
    linkedin_url: string;
    location_name: string;
    // Add other properties as needed
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Lead;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    { id: 'full_name', numeric: false, disablePadding: false, label: 'Full Name' },
    { id: 'industry', numeric: false, disablePadding: false, label: 'Industry' },
    { id: 'linkedin_url', numeric: false, disablePadding: false, label: 'LinkedIn URL' },
    { id: 'location_name', numeric: false, disablePadding: false, label: 'Location' },
];

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: { numSelected: number; onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void; order: Order; orderBy: string; onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Lead) => void; rowCount: number }) {
    const { onSelectAllClick, order, orderBy, numSelected, onRequestSort, rowCount } = props;
    const createSortHandler = (property: keyof Lead) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };



    return (
        <TableHead >
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={props.rowCount > 0 && numSelected === props.rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all leads' }}
                    />
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            height: '56px', // Fixed height for header cells
                            whiteSpace: 'nowrap', // Prevent text wrapping
                            overflow: 'hidden', // Hide overflowed text
                            textOverflow: 'ellipsis' // Add ellipsis for overflowed text
                        }}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// Function to clear the selection

function EnhancedTableToolbar(props: { numSelected: number, onClearSelection: () => void, onPartialDeleteSelected: () => void, onAddToCollection: (selectedLeads: string[]) => void }) {
    const { numSelected, onClearSelection, onPartialDeleteSelected } = props;



    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        theme.palette.secondary.light,
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle" component="div">
                    Leads
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <CustomTooltip title="Remove All">
                        <IconButton onClick={onClearSelection}>
                            <BackspaceSharpIcon />
                        </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title="Remove Selected">
                        <IconButton onClick={onPartialDeleteSelected}>
                            {/* Replace with the desired icon */}
                            <DeleteSharpIcon />
                        </IconButton>
                    </CustomTooltip>
                </>

            ) : (
                <CustomTooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </CustomTooltip>
            )}
        </Toolbar>
    );
}

const SelectedLeadsDisplay = ({ selectedLeads, onSelectionModelChange, onSelectedLeadsChange }) => {
    const [leadsData, setLeadsData] = useState<Lead[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Lead>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [dense, setDense] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [collections, setCollections] = useState<Collection[]>([]);
    const [selectedCollection, setSelectedCollection] = useState('');


    // Function to handle partial deletion
    const handlePartialDeleteSelected = () => {
        // Logic to remove selected leads
        const updatedSelected = leadsData.filter(lead => !selected.includes(lead.id)).map(lead => lead.id);
        setSelected(updatedSelected);

        // Update the parent component's state
        onSelectionModelChange(updatedSelected);
        onSelectedLeadsChange(updatedSelected);
    };

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/leads/retrieve`, {
                    leadIds: selectedLeads
                });
                setLeadsData(response.data.leads);
            } catch (error) {
                console.error('Error fetching leads:', error);
            }
        };

        fetchLeads();
    }, [selectedLeads]);


    //fetch collections: 
    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/collections`);
                setCollections(response.data.collections);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };

        fetchCollections();
    }, []);

    // add bulk to collection: 

    const handleAddToCollection = async (selectedLeads: string[]) => {
        if (!selectedCollection) {
            alert("Please select a collection first.");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/revealcontactinfo/bulk`, {
                leadIds: selectedLeads,
                collectionId: selectedCollection
            });
            alert(`Leads Saved: ${response.data.count}`);
        } catch (error) {
            console.error('Error adding leads to collection:', error);
            alert('Error adding leads to collection.');
        }
    };


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Lead) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = leadsData.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%', Height: 600 }}>
            <Paper sx={{ width: '100%', Height: 600, mb: 2 }}>

                <Select
                    value={selectedCollection}
                    onChange={(event) => setSelectedCollection(event.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        Select a Collection
                    </MenuItem>
                    {collections.map((collection) => (
                        <MenuItem key={collection._id} value={collection._id}>
                            {collection.collectionName}
                        </MenuItem>
                    ))}
                </Select>

                {selected.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddToCollection(selected)}
                    >
                        Add to Collection
                    </Button>
                )}

                <EnhancedTableToolbar
                    numSelected={selected.length}
                    onClearSelection={() => {
                        onSelectionModelChange([]);
                        onSelectedLeadsChange([]);
                    }}
                    onPartialDeleteSelected={handlePartialDeleteSelected}
                    onAddToCollection={handleAddToCollection}
                />
                <TableContainer sx={{ Height: 600 }} >
                    <Table
                        sx={{ minWidth: 750, Height: 600 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={leadsData.length}

                        />
                        <TableBody>
                            {stableSort(leadsData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((lead, index) => {
                                    const isItemSelected = isSelected(lead.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, lead.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={lead.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{lead.full_name}</TableCell>
                                            <TableCell align="left">{lead.industry}</TableCell>
                                            <TableCell align="left">{lead.linkedin_url}</TableCell>
                                            <TableCell align="left">{lead.location_name}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={leadsData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={(event) => setDense(event.target.checked)} />}
                label="Dense padding"
            />
        </Box>
    );
};

export default SelectedLeadsDisplay;
