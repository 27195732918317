import React, { FC, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { ActivityDrawer, DrawerMessenger, RightToolbar } from '../partials'
import { themeModeSwitchHelper, useThemeMode } from '../partials/layout/theme-mode/ThemeModeProvider'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { WithChildren } from '../helpers'
import { useGlassEffect } from './components/header/GlassEffectContext'
import { useAuth } from '../../app/modules/auth'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import CloseSharp from '@mui/icons-material/CloseSharp';
const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()
  const { mode } = useThemeMode()
  const location = useLocation()
  const { isGlassEffectEnabled } = useGlassEffect();
  const { currentUser } = useAuth(); // Use the useAuth hook to get the current user info
  const theme = useTheme()
  const [isBannerVisible, setIsBannerVisible] = useState(true); // State to manage banner visibility


  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  useEffect(() => {
    console.log(currentUser?.subscriptionId?.plan)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  // Calculate remaining days for trial
  const calculateRemainingTrialDays = () => {
    if (currentUser && currentUser.subscriptionId && currentUser.subscriptionId.trialEnd) {
      const trialEndDate = new Date(currentUser.subscriptionId.trialEnd);
      const currentDate = new Date();
      const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays;
    }
    return 0; // Return 0 if there's no trialEnd date available
  };

  const isPlanTrial = () => {
    return currentUser?.subscriptionId?.plan === 'trial';
  };



  // Adjust this value to match the height of your header
  const contentStyle = {
    paddingTop: '80px', // Replace 60px with the actual height of your header
  };

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>

        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {isPlanTrial() && isBannerVisible && (
            <Paper
              color="secondary"
              sx={{
                textAlign: 'center',
                borderRadius: "0px",
                padding: '10px',
                marginTop: '70px',
                marginBottom: '-70px',
                position: 'relative', // Add this for positioning the close button
                background: (theme) => theme.palette.secondary.main,
              }}
            >
              <IconButton
                onClick={handleCloseBanner}
                size="small"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                }}
              >
                <CloseSharp />
              </IconButton>
              <Typography color="white" variant="body1" component="p">
                Your trial period is active.{' '}
                <Link to="/upgrade" style={{ color: 'inherit', textDecoration: 'none' }}>
                  Upgrade now!
                </Link>{' '}
                Your trial ends in {calculateRemainingTrialDays()} days.
              </Typography>
            </Paper>
          )}

          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid' style={contentStyle}>
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {/*  <Footer />  */}
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/*  <RightToolbar />  */}
      {/* <DrawerMessenger />  */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers /> */}
      {/* <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export { MasterLayout }
