import React, { useState } from 'react';
import { Stepper, Step, StepButton, Grid, Tooltip, useTheme } from '@mui/material';
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp'; import ContactsIcon from '@mui/icons-material/Contacts';
import EditCalendarSharpIcon from '@mui/icons-material/EditCalendarSharp';
import CampaignStatistics from '../1-CampaignStatistics/CampaignStatistics';
// import CampaignName from '../1-CampaignName/CampaignName';
import SequenceComponent from '../3-CampaignSequence/CampaignSequence';
import CampaignSettings from '../4-CampaignSchedule/CampaignSettings';
import CampaignSchedule from '../4-CampaignSchedule/CampaignSchedule';
import CampaignLeads from '../2-CampaignLeads/CampaignLeads';
import CampaignReview from '../5-CampaignReview/CampaignReviewParent';
import { AnimatePresence, motion } from 'framer-motion';
import Groups2SharpIcon from '@mui/icons-material/Groups2Sharp';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import SettingsSuggestSharpIcon from '@mui/icons-material/SettingsSuggestSharp';
import GradingSharpIcon from '@mui/icons-material/GradingSharp';
import BarChartSharpIcon from '@mui/icons-material/BarChartSharp';

import Box from '@mui/material/Box';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';

interface CampaignStepperProps {
    selectedCampaignId: string | null;
}

const CampaignStepper: React.FC<CampaignStepperProps> = ({ selectedCampaignId }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [direction, setDirection] = useState(0);

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    const hoverEffect = {
        hover: {
            scale: 1.1, // Increased scale for more pronounced effect
            rotate: 0.1, // Adding a slight rotation
            transition: {
                type: 'spring',
                stiffness: 300, // More stiffness for a springy feel
                damping: 10,
            },
        },
    };

    const springTransition = {
        type: 'spring',
        damping: 12, // Adjusted for a springier feel
        stiffness: 150, // Higher stiffness
    };

    const directionVariants = {
        enter: (direction: number) => ({
            x: direction > 0 ? 500 : -500, // Reduced distance for subtlety
            opacity: 0,
            scale: 0.50, // Slightly smaller to add to the effect
        }),
        center: {
            // zIndex: 1,
            x: 0,
            opacity: 1,
            scale: 1,
        },
        exit: (direction: number) => ({
            // zIndex: 0,
            x: direction > 0 ? -500 : 500, // Reduced distance for subtlety
            opacity: 0,
            scale: 0.50, // Slightly smaller to add to the effect
        }),
    };





    const labelVariants = {
        active: {
            opacity: 1,
            scale: 1, // Slightly overshooting the scale for a 'pop' effect
            transition: {
                type: 'spring',
                stiffness: 100,
                damping: 13
            }
        },
        inactive: {
            opacity: 0,
            scale: 0,
            transition: {
                duration: 0.3
            }
        }
    };



    const steps = [
        { label: 'Campaign Statistics', icon: <BarChartSharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        // { label: 'Campaign Name', icon: <CampaignSharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        { label: 'Leads', icon: <Groups2SharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        { label: 'Sequence', icon: <TextSnippetSharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        { label: 'Settings', icon: <SettingsSuggestSharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        { label: 'Schedule', icon: <EditCalendarSharpIcon color='primary' sx={{ fontSize: "35px" }} /> },
        { label: 'Review', icon: <GradingSharpIcon color='primary' sx={{ fontSize: "35px" }} /> }
    ];


    const handleNextStep = () => {
        setActiveStep(prevStep => prevStep + 1);
        setDirection(1); // Explicitly set the direction for forward movement
    };

    const stepContent = [
        <CampaignStatistics selectedCampaignId={selectedCampaignId} onNextStep={handleNextStep} />,
        // <CampaignName selectedCampaignId={selectedCampaignId} onNextStep={handleNextStep} />,
        <CampaignLeads selectedCampaignId={selectedCampaignId} />,
        <SequenceComponent selectedCampaignId={selectedCampaignId} />,
        <CampaignSettings selectedCampaignId={selectedCampaignId} />,
        <CampaignSchedule selectedCampaignId={selectedCampaignId} />,
        <CampaignReview selectedCampaignId={selectedCampaignId} />,
    ];



    const handleStep = (step: number) => () => {
        const newDirection = step > activeStep ? 1 : -1;
        setDirection(newDirection);
        setActiveStep(step);
    };

    const theme = useTheme()

    return (
        <Grid sx={{ marginTop: "-45px" }}>
            <Stepper nonLinear activeStep={activeStep} sx={{ marginBottom: "20px" }}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <Tooltip
                            title={step.label}
                            placement="top"
                            followCursor
                        >
                            <motion.div
                                className="no-global-transition"

                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -50 }}
                                transition={springTransition}
                                whileHover="hover"
                                variants={hoverEffect}
                                whileTap={{ scale: 0.7, rotate: -6, transition: spring }}
                            >


                                <StepButton onClick={handleStep(index)} icon={step.icon} sx={{
                                    color: "#ebebeb",
                                    '& .MuiTouchRipple-root': {
                                        color: '#793A99', // Replace with your desired color
                                    }
                                }}
                                >
                                    {index === activeStep && (
                                        <motion.div
                                            variants={labelVariants}
                                            initial="inactive"
                                            animate="active"
                                            exit="inactive"
                                            className="step-label"
                                        >
                                            <Box fontWeight={500} color="info.main">
                                                {step.label}
                                            </Box>
                                        </motion.div>
                                    )}
                                </StepButton>
                            </motion.div>
                        </Tooltip>
                    </Step>
                ))}
            </Stepper>
            <Grid style={{ position: 'relative', maxHeight: '350px', maxWidth: "98%" }}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        className="no-global-transition"

                        key={activeStep}
                        custom={direction}
                        variants={directionVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                            scale: { duration: 0.2 },
                            ease: "easeInOut", // Added ease for a smoother transition
                        }}

                        style={{ position: 'absolute', width: '100%' }}
                    >

                        {stepContent[activeStep]}
                    </motion.div>
                </AnimatePresence>
            </Grid>
        </Grid>
    );
};

export default CampaignStepper;

