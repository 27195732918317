import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';

interface SMSFormProps {
    fromNumber: string;
    toNumber: string;
}

const SMSForm: React.FC<SMSFormProps> = ({ fromNumber, toNumber }) => {
    const [body, setBody] = useState('');
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const [delayInSeconds, setDelayInSeconds] = useState('');

    const handleSendNow = async () => {
        const payload = {
            to: toNumber,
            body,
            fromNumber,
            sendType: 'immediate',
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/send-manual-sms`, payload);
            alert('SMS sent successfully');
        } catch (error) {
            console.error('Failed to send SMS:', error);
            alert('Failed to send SMS');
        }
    };

    const handleOpenSchedule = () => {
        setScheduleOpen(true);
    };

    const handleScheduleSMS = async () => {
        const payload = {
            to: toNumber,
            body,
            fromNumber,
            sendType: 'scheduled',
            delayInSeconds,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/send-manual-sms`, payload);
            setScheduleOpen(false);
            alert('SMS scheduling successful');
        } catch (error) {
            console.error('Failed to schedule SMS:', error);
            alert('Failed to schedule SMS');
        }
    };

    return (
        <div>
            <TextField
                label="Message Body"
                value={body}
                multiline
                placeholder={`Reply from ${fromNumber} to ${toNumber}...`}
                rows={4}
                onChange={(e) => setBody(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleSendNow} variant="contained" color="primary">
                Send Now
            </Button>
            <Button onClick={handleOpenSchedule} variant="contained" color="secondary">
                Schedule
            </Button>

            <Dialog open={scheduleOpen} onClose={() => setScheduleOpen(false)}>
                <DialogTitle>Schedule SMS</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Delay in Seconds"
                        type="number"
                        fullWidth
                        value={delayInSeconds}
                        onChange={(e) => setDelayInSeconds(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setScheduleOpen(false)}>Cancel</Button>
                    <Button onClick={handleScheduleSMS} color="primary">
                        Schedule SMS
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SMSForm;
