import { cn } from '@/src/lib/utils';
import { Box, Card, Chip, Paper, Typography, useTheme } from '@mui/material';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { InboxIcon } from 'lucide-react';
import React from 'react';

import { Mail } from '../data';
import { useMail } from '../use-mail';

interface MailListProps {
  items: Mail[]; // Ensure your Mail type includes number1 and number2 for SMS conversations
}

export function MailList({ items }: MailListProps) {
  const [mail, setMail] = useMail();
  const theme = useTheme();

  // Check if items array is empty
  if (items.length === 0) {
    return (
      <Paper style={{ overflow: "auto", height: "100%" }} className="flex justify-center items-center">
        <Box textAlign="center">
          <InboxIcon color="info" style={{ fontSize: 60 }} />
          <Typography variant="subtitle1">Nothing in here</Typography>
        </Box>
      </Paper>
    );
  }

  // Utility function to determine if the color is light or dark
  function isLight(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    // Using the YIQ equation to determine the brightness of the color
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128; // Light colors will return true, dark colors will return false
  }



  return (
    <Paper style={{ overflow: "auto" }} className="h-screen">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items.map((item) => (
          <Card
            elevation={0}
            sx={{
              borderRadius: "0px",
              border: "solid 2px #F1F1F2",
              cursor: "pointer",
              transition: "all 0.3s",
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              backgroundColor: mail.selected === item.id ? theme.palette.action.selected : 'inherit',
            }} key={item.id}
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg p-3 text-left text-sm",
            )}
            onClick={() => setMail({ ...mail, selected: item.id })}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center justify-between">
                <div>
                  <Typography variant="subtitle1" className="font-semibold">{item.name}</Typography>
                  {!item.read && <span className="flex h-2 w-2 rounded-full bg-blue-600" />}
                </div>
                <Typography variant="caption" className={cn("ml-auto", mail.selected === item.id ? "text-foreground" : "text-muted-foreground")}>
                  {
                    //@ts-ignore
                    item.date && isValid(parseISO(item.date)) ? formatDistanceToNow(new Date(item.date), { addSuffix: true }) : 'Date unknown'}
                </Typography>
              </div>
              <Typography variant="body2">{item.subject}</Typography>
              {/* Displaying number1 and number2 */}
              {item.number1 && item.number2 && (
                <Typography variant="body2" className="text-xs">Numbers: {item.number1}, {item.number2}</Typography>
              )}
            </div>
            {item.tags.length ? (
              <div className="flex items-center gap-2">
                {item.tags.map((tag) => (
                  <Chip key={tag.name} style={{ backgroundColor: tag.color, color: isLight(tag.color) ? 'black' : 'white', }} label={tag.name} />
                ))}
              </div>
            ) : null}
          </Card>
        ))}
      </div>
    </Paper>
  );
}
