// src\app\pages\5-Inbox\mail\page.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { MailComponent } from './components/mail';
import { PhoneIcon } from 'lucide-react';
import { Box, Button, Typography } from '@mui/material';
import MailSkeleton from './components/MailSkeletonLoading';
import { RefreshSharp } from '@mui/icons-material';
interface SelectedAccountType {
  type: string;
  value: string;
}


export default function MailPage() {
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccountType>({ type: 'all', value: 'all' });
  const [accounts, setAccounts] = useState([]);
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        // Fetch email accounts
        const accountsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/inbox/accounts`);
        let updatedAccounts = accountsResponse.data.map(account => ({
          ...account,
          type: 'email', // Add a type to distinguish between emails and phone numbers
          icon: account.icon // Assuming each account already has an icon
        }));

        // Fetch phone numbers
        const phoneNumbersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/phone/twilio-numbers`);
        console.log("Phone numbers response data:", phoneNumbersResponse.data); // Add this line to log the response

        if (phoneNumbersResponse.data.phoneNumbers && Array.isArray(phoneNumbersResponse.data.phoneNumbers)) {
          const phoneAccounts = phoneNumbersResponse.data.phoneNumbers.map(phoneNumber => ({
            label: phoneNumber.phoneNumber,
            emailAccountId: phoneNumber.phoneNumber,
            icon: <PhoneIcon />,
            type: 'phone' // Distinguish as phone type
          }));

          updatedAccounts = [...updatedAccounts, ...phoneAccounts];
        } else {
          console.error("Expected an array for phone numbers, received:", phoneNumbersResponse.data);
        }

        if (updatedAccounts.length > 0) {
          setSelectedAccount({ type: 'all', value: 'all' });
          setAccounts(updatedAccounts);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
    fetchAccounts();
  }, []);



  // Adjust the fetchEmails function
  const fetchData = async ({ queryKey }) => {
    const [_, account] = queryKey;

    // Handle fetching for "All" accounts
    if (account.type === 'all') {
      // Assuming there's a general endpoint for fetching all messages
      const url = `${process.env.REACT_APP_API_URL}/inbox/emails`;
      return axios.get(url).then(response => response.data);
    }

    // Handle fetching for specific email accounts
    if (account.type === 'email') {
      const url = `${process.env.REACT_APP_API_URL}/inbox/emails/${account.value}`;
      return axios.get(url).then(response => response.data);
    }

    // Handle fetching for specific phone accounts (SMS conversations)
    if (account.type === 'phone') {
      const url = `${process.env.REACT_APP_API_URL}/phone/conversations/byNumber/${encodeURIComponent(account.value)}`;
      return axios.get(url).then(response => response.data.conversations);
    }
  };


  const { data: items, isLoading, isError, refetch } = useQuery(['items', selectedAccount], fetchData, {
    enabled: !!selectedAccount.value,
  });

  const onAccountChange = (accountId: string, accountType: string) => {
    setSelectedAccount({ type: accountType, value: accountId });
    queryClient.invalidateQueries(['items', selectedAccount]);
  };


  const defaultLayout = [265, 440, 655];
  const defaultCollapsed = false;

  if (isLoading) return <div><MailSkeleton /></div>;

  if (isError)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" onClick={() => refetch()}      >
        <Typography variant="h6" color="error">Please Retry!.</Typography>
        <Button
          startIcon={<RefreshSharp />}
          size="large"
        >
          Retry
        </Button>
      </Box>
    );

  return (
    <>
      <div className="hidden md:flex flex-col">

        <MailComponent
          accounts={accounts}
          mails={items || []} // Note: Adjust MailComponent prop to accept items if it only accepts mails.
          defaultLayout={defaultLayout}
          defaultCollapsed={defaultCollapsed}
          navCollapsedSize={4}
          onAccountChange={onAccountChange}
          selectedAccountId={selectedAccount.value}
          selectedAccount={selectedAccount}

        />
      </div>
    </>
  );
}
