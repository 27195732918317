//src\app\pages\LeadGenAdvanced\Parent-LeadGenAdvanced.tsx
import './AdvancedLeadGenParent.css';

import { useAuth } from '@/src/app/modules/auth';
import { CleanHandsSharp } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Drawer, Grid, IconButton, Paper } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import DraggableDialog from '../../../modules/CustomMaterialUiComponents/DraggableDialog';
import Collections from './Collections/CollectionsParent';
import ContentArea from './ContentArea';
import LeadDetailDialog from './LeadDetailDialog';
import LeadImportComponent from './LeadImportComponent';
import SaveSearchComponent from './SaveSearchComponent';
import SelectedLeadsDisplay from './SelectedLeadsDisplay';
import SidePanel from './SidePanel';

const AdvancedLeadGenParent = () => {
    const { currentUser } = useAuth();
    const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false); // State to control the drawer
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [activeSearchId, setActiveSearchId] = useState("");
    const [activeSearchName, setActiveSearchName] = useState("");
    const [isUpdating, setIsUpdating] = useState(false); // State to track updating status
    const [success, setSuccess] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [exactJobTitle, setExactJobTitle] = useState(true); // New state

    const [searchQuery, setSearchQuery] = useState('');
    const [leads, setLeads] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [actualPage, setActualPage] = useState(Math.floor(Math.random() * 100)); // Random starting page
    const [userVisiblePage, setUserVisiblePage] = useState(0); // Starts at 0, representing page 1 for the user
    const [rowCount, setRowCount] = useState(0);
    const [selectedIndustry, setSelectedIndustry] = useState<string[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [excludedJobTitles, setExcludedJobTitles] = useState<string[]>([]);
    const [selectedJobTitle, setSelectedJobTitle] = useState<string[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<{ code: string; name: string; }[]>([]);
    const [selectedContinent, setSelectedContinent] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [excludedRegion, setExcludedRegion] = useState<string[]>([]);
    const [selectedGender, setSelectedGender] = useState<string>('');
    const [hobbies, setHobbies] = useState<string[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [foundedBefore, setFoundedBefore] = useState('');
    const [foundedAfter, setFoundedAfter] = useState('');
    const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

    const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
    const [isLeadImportDialogOpen, setIsLeadImportDialogOpen] = useState(false);

    const [isIndustrySelected, setIsIndustrySelected] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false);

    const [hasPhone, setHasPhone] = useState(false);
    const [hasPersonalEmail, setHasPersonalEmail] = useState(false);
    const [hasProfessionalEmail, setHasProfessionalEmail] = useState(false);


    const [debugQuery, setDebugQuery] = useState('');


    const handleSelectedLeadsChange = (newSelectedLeads) => {
        setSelectedLeads(newSelectedLeads);
    };

    const handleOpenSaveDialog = () => {
        setIsSaveDialogOpen(true);
    };

    const handleCloseSaveDialog = () => {
        setIsSaveDialogOpen(false);
    };

    const toggleLeadImportDialog = () => {
        setIsLeadImportDialogOpen(!isLeadImportDialogOpen);
    };

    // Callback function to update activeSearchId and name
    const handleActiveSearchIdChange = (id) => {
        setActiveSearchId(id);
    };
    const handleActiveSearchNameChange = (name: string) => {
        setActiveSearchName(name);
    };

    const handleOpenCollectionsDialog = () => {
        setIsCollectionsDialogOpen(true);
    };



    const timerRef = useRef<number>();


    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setIsIndustrySelected(selectedIndustry.length > 0);
        setIsCountrySelected(
            selectedCountries.length > 0 ||
            selectedContinent.length > 0 ||
            selectedRegion.length > 0
        );
    }, [selectedIndustry, selectedCountries, selectedContinent, selectedRegion]);



    useEffect(() => {
        const fetchLeads = async () => {

            if (!isIndustrySelected || !isCountrySelected) {
                setLeads([]);
                setRowCount(0);
                setTotalPages(0);
                setLoading(false);
                return;
            }

            setLoading(true); // Start loading
            // Ensure actualPage is within valid range before making API call
            const validPage = Math.max(0, Math.min(actualPage, totalPages - 1));

            // Construct queryParams dynamically based on whether the filters have values
            const queryParams = {
                page: validPage + 1,
                limit: pageSize,
                ...(selectedIndustry.length > 0 && { industry: selectedIndustry.join(';') }),
                ...(selectedJobTitle.length > 0 && { job_title: selectedJobTitle.join(';'), exact_job_title: exactJobTitle }),
                ...(excludedJobTitles.length > 0 && { exclude_job_title: excludedJobTitles.join(';') }),
                ...(selectedCountries.length > 0 && { include_country: selectedCountries.map(c => c.name).join(';') }),
                ...(selectedContinent.length > 0 && { include_continent: selectedContinent.join(';') }),
                ...(selectedRegion.length > 0 && { include_region: selectedRegion.join(';') }),
                ...(excludedRegion.length > 0 && { exclude_region: excludedRegion.join(';') }),
                ...(selectedGender && { gender: selectedGender }),
                ...(hobbies.length > 0 && { hobbies: hobbies.join(';') }),
                ...(skills.length > 0 && { skills: skills.join(';') }),
                ...(foundedBefore && { founded_before: foundedBefore }),
                ...(foundedAfter && { founded_after: foundedAfter }),
                ...(hasPhone && { has_phone: 'true' }),
                ...(hasPersonalEmail && { has_personal_email: 'true' }),
                ...(hasProfessionalEmail && { has_professional_email: 'true' }),
            };



            const queryString = Object.keys(queryParams)
                .map(key => `${key}=${queryParams[key]}`)
                .join('&');

            setDebugQuery(queryString);

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/search`, { params: queryParams });
                if (response.data) {
                    setLeads(response.data.leads);
                    setRowCount(response.data.total);
                    setTotalPages(Math.ceil(response.data.total / pageSize));
                }
            } catch (error) {
                console.error('Error fetching leads:', error);
                setLeads([]);
                setRowCount(0);
                setTotalPages(0);
            } finally {
                setLoading(false); // Set loading to false regardless of success or error
            }
        };
        fetchLeads();
    }, [actualPage, pageSize, selectedIndustry, selectedJobTitle, selectedCountries, selectedContinent, selectedRegion, excludedRegion, selectedGender, hobbies, skills, totalPages, foundedAfter, foundedBefore, debugQuery, isIndustrySelected, isCountrySelected, hasPhone, hasPersonalEmail, hasProfessionalEmail, exactJobTitle, excludedJobTitles]);



    // Adjust actualPage and userVisiblePage when totalPages changes
    useEffect(() => {
        if (totalPages > 1) { // Check if there are at least two pages
            // Select a random page from 0 to totalPages-2, thus excluding the last page
            setActualPage(Math.floor(Math.random() * (totalPages - 1)));
            setUserVisiblePage(0); // Reset user visible page to 0 (page 1 for the user)
        } else if (totalPages === 1) {
            // If there is only one page, set it to the first page
            setActualPage(0);
            setUserVisiblePage(0);
        }
    }, [totalPages, selectedIndustry, selectedJobTitle, selectedCountries, selectedContinent, selectedRegion, excludedRegion, selectedGender, hobbies, skills, totalPages, foundedAfter, foundedBefore]);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        let newActualPage = model.page + actualPage - userVisiblePage;

        // Ensure newActualPage is within valid range
        newActualPage = Math.max(0, Math.min(newActualPage, totalPages - 1));

        setUserVisiblePage(model.page);
        setActualPage(newActualPage);
        setPageSize(model.pageSize);
    };

    const paginationModel = {
        page: userVisiblePage,
        pageSize: pageSize,
    };

    const handleSelectionModelChange = (newSelection: string[]) => {
        setSelectedLeads(newSelection);
    };


    const handleViewLead = (leadId: string) => {
        setSelectedLeadId(leadId);
    };


    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    // Function to clear the selection
    const handleClearSelection = () => {
        handleSelectionModelChange([]);
        handleSelectedLeadsChange([]);
    };

    const circularDialogStyles = {
        style: {
            borderRadius: '10%',
            width: '600px', // Fixed width
            height: '600px', // Fixed height to maintain circle shape
            overflow: 'hidden', // Prevent content from spilling out
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const handleUpdateSearch = async () => {
        setSuccess(false);
        setUpdateLoading(true);

        const updateData = {
            searchId: activeSearchId,
            searchName: activeSearchName,
            searchParams: {
                selectedIndustry: selectedIndustry,
                excludedJobTitles: excludedJobTitles,
                selectedJobTitle: selectedJobTitle,
                selectedCountries: selectedCountries,
                selectedContinent: selectedContinent,
                selectedRegion: selectedRegion,
                excludedRegion: excludedRegion,
                selectedGender: selectedGender,
                hobbies: hobbies,
                skills: skills,
                foundedBefore: foundedBefore,
                foundedAfter: foundedAfter
            },
            actualPage: actualPage,
            userVisiblePage: userVisiblePage,
            selectedLeads: selectedLeads
        };
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/advancedleadgen/update-search`, updateData);
            console.log('Search Updated:', response.data);

            // Ensure loading state is shown for at least 1 second
            timerRef.current = window.setTimeout(() => {
                setSuccess(true); // Set success true here, after the fake loading time

                // Reset success state after an additional delay
                timerRef.current = window.setTimeout(() => {
                    setSuccess(false);
                }, 3000); // Time for sucess message
                setUpdateLoading(false); // End the updating process
            }, 4000); // Fake loading time

        } catch (error) {
            console.error('Error updating search:', error);
            setSuccess(false); // Optionally set success to false in case of an error
            setUpdateLoading(false); // End the updating process immediately in case of an error
        }
    };

    // Animations

    const variants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 }
    };

    const handleDeleteNoEmailLeads = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/advancedleadgen/delete-no-emails`);
            console.log('Deleted:', response.data); // Or handle this response in a more user-friendly way
            // Optionally refresh your leads list or show a success message
        } catch (error) {
            console.error('Error deleting leads with no emails:', error);
            // Optionally handle error, e.g., show an error message
        }
    };

    const filters = {
        selectedIndustry,
        selectedJobTitle,
        selectedCountries,
        selectedContinent,
        selectedRegion,
        excludedRegion,
        selectedGender,
        hobbies,
        skills,
        foundedBefore,
        foundedAfter,
        hasPhone,
        hasPersonalEmail,
        hasProfessionalEmail,
        exactJobTitle,
        excludedJobTitles
    };

    return (
        <div className="root">

            <Grid container>


                {/* Drawer component */}
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                    <Box sx={{ maxWidth: '500px', width: '100%', padding: '16px' }}>
                        <SelectedLeadsDisplay
                            selectedLeads={selectedLeads}
                            onSelectionModelChange={handleSelectionModelChange}
                            onSelectedLeadsChange={handleSelectedLeadsChange}
                        />
                    </Box>
                </Drawer>


                {/* Save Search Dialog */}
                <DraggableDialog
                    open={isSaveDialogOpen}
                    onClose={handleCloseSaveDialog}
                    title="Save Search"
                    enableExternalWindow={false}

                    content={
                        <SaveSearchComponent
                            selectedIndustry={selectedIndustry}
                            setSelectedIndustry={setSelectedIndustry}
                            excludedJobTitles={excludedJobTitles}
                            setExcludedJobTitles={setExcludedJobTitles}
                            selectedJobTitle={selectedJobTitle}
                            setSelectedJobTitle={setSelectedJobTitle}
                            selectedCountries={selectedCountries}
                            setSelectedCountries={setSelectedCountries}
                            selectedContinent={selectedContinent}
                            setSelectedContinent={setSelectedContinent}
                            selectedRegion={selectedRegion}
                            setSelectedRegion={setSelectedRegion}
                            excludedRegion={excludedRegion}
                            setExcludedRegion={setExcludedRegion}
                            selectedGender={selectedGender}
                            setSelectedGender={setSelectedGender}
                            hobbies={hobbies}
                            setHobbies={setHobbies}
                            skills={skills}
                            setSkills={setSkills}
                            foundedBefore={foundedBefore}
                            setFoundedBefore={setFoundedBefore}
                            foundedAfter={foundedAfter}
                            setFoundedAfter={setFoundedAfter}
                            actualPage={actualPage}
                            setActualPage={setActualPage}
                            userVisiblePage={userVisiblePage}
                            setUserVisiblePage={setUserVisiblePage}
                            selectedLeads={selectedLeads}
                            setSelectedLeads={setSelectedLeads}
                            activeSearchId={activeSearchId}
                            activeSearchName={activeSearchName}
                            onActiveSearchIdChange={setActiveSearchId}
                            onActiveSearchNameChange={setActiveSearchName}

                        />
                    }
                />


                <DraggableDialog
                    open={isLeadImportDialogOpen}
                    onClose={toggleLeadImportDialog}
                    title="Import Leads"
                    content={<LeadImportComponent selectedLeads={selectedLeads} filters={filters} />}
                />


                <DraggableDialog
                    open={isCollectionsDialogOpen}
                    onClose={() => setIsCollectionsDialogOpen(false)}
                    title="Lead Collections"
                    content={<Collections />}
                />


                {/* Side Panel and Content Area wrapped in one Paper */}
                <Grid item xs={12}>
                    <Paper className="sidePanelAndContent">

                        <Grid item xs={12}>

                        </Grid>


                        <IconButton onClick={() => setIsFilterPanelVisible(!isFilterPanelVisible)}>
                            {isFilterPanelVisible ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>

                        <Grid container spacing={2}>
                            {/* Side Panel */}
                            {isFilterPanelVisible && (

                                <Grid item xs={isFilterPanelVisible ? 3 : 0} className={isFilterPanelVisible ? 'sidePanel' : 'sidePanelHidden'}>



                                    <SidePanel
                                        selectedIndustry={selectedIndustry}
                                        setSelectedIndustry={(value) => { setSelectedIndustry(value); setIsIndustrySelected(value.length > 0); }}
                                        selectedJobTitle={selectedJobTitle}
                                        setSelectedJobTitle={setSelectedJobTitle}
                                        excludedJobTitles={excludedJobTitles}
                                        setExcludedJobTitles={setExcludedJobTitles}
                                        selectedCountries={selectedCountries}
                                        setSelectedCountries={(value) => { setSelectedCountries(value); setIsCountrySelected(value.length > 0); }}
                                        setSelectedContinent={setSelectedContinent}
                                        selectedContinent={selectedContinent}
                                        selectedRegion={selectedRegion}
                                        setSelectedRegion={setSelectedRegion}
                                        excludedRegion={excludedRegion}
                                        setExcludedRegion={setExcludedRegion}
                                        selectedGender={selectedGender}
                                        setSelectedGender={setSelectedGender}
                                        hobbies={hobbies}
                                        setHobbies={setHobbies}
                                        skills={skills}
                                        setSkills={setSkills}
                                        foundedBefore={foundedBefore}
                                        setFoundedBefore={setFoundedBefore}
                                        foundedAfter={foundedAfter}
                                        setFoundedAfter={setFoundedAfter}
                                        hasPhone={hasPhone}
                                        setHasPhone={setHasPhone}
                                        hasPersonalEmail={hasPersonalEmail}
                                        setHasPersonalEmail={setHasPersonalEmail}
                                        hasProfessionalEmail={hasProfessionalEmail}
                                        setHasProfessionalEmail={setHasProfessionalEmail}
                                        exactJobTitle={exactJobTitle}
                                        setExactJobTitle={setExactJobTitle}
                                    />
                                </Grid>
                            )}

                            {/* Content Area */}
                            <Grid item xs={isFilterPanelVisible ? 9 : 12}>
                                <ContentArea
                                    leads={leads}
                                    paginationModel={paginationModel}
                                    setPaginationModel={handlePaginationModelChange}
                                    rowCount={rowCount}
                                    selectedLeads={selectedLeads}
                                    onSelectionModelChange={handleSelectionModelChange}
                                    onSelectedLeadsChange={handleSelectedLeadsChange}
                                    loading={loading}
                                    onViewLead={handleViewLead}
                                    handleUpdateSearch={handleUpdateSearch}
                                    handleOpenSaveDialog={handleOpenSaveDialog}
                                    toggleDrawer={toggleDrawer}
                                    success={success}
                                    activeSearchId={activeSearchId}
                                    activeSearchName={activeSearchName}
                                    updateLoading={updateLoading}
                                    handleOpenCollectionsDialog={handleOpenCollectionsDialog}
                                    toggleLeadImportDialog={toggleLeadImportDialog}
                                    isIndustrySelected={isIndustrySelected} // Pass industry selected state
                                    isCountrySelected={isCountrySelected} // Pass country selected state
                                    onIndustryClick={() => setIsFilterPanelVisible(true)} // Add callback to show filter panel
                                    onCountryClick={() => setIsFilterPanelVisible(true)} // Add callback to show filter panel                                
                                />

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {
                    selectedLeadId && (
                        <LeadDetailDialog
                            leadId={selectedLeadId}
                            onClose={() => setSelectedLeadId(null)}
                        />
                    )
                }



                {currentUser && currentUser.admin && (
                    <>
                        {/* Debug Info */}
                        <Grid item xs={12}>
                            <Paper className="content" sx={{ padding: "30px", marginTop: "100px" }}>
                                <strong>Debug Info:</strong>
                                <div>User Visible Page (Fake Page): {userVisiblePage + 1}</div>
                                <div>Actual Page (API Request Page): {actualPage + 1}</div>
                                <div>Page Size: {pageSize}</div>
                                <div>Row Count: {rowCount}</div>
                                <div>Total Pages: {totalPages}</div>
                                <div>Debug Query: {debugQuery}</div> {/* Display the query here */}
                                {/*         <div>Leads: {JSON.stringify(leads)}</div> */}
                            </Paper>
                        </Grid>

                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteNoEmailLeads}
                            style={{ margin: '10px' }} // Adjust styling as needed
                        >
                            <CleanHandsSharp />

                        </Button>
                    </>

                )}

            </Grid>
        </div>
    );
};

export default AdvancedLeadGenParent;
