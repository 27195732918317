// src\app\pages\LeadGenAdvanced\SidePanel.tsx
import React, { useRef } from 'react';
import { FormControl, Autocomplete, TextField, Box, Grid, Typography, Select, MenuItem, InputLabel, RadioGroup, FormControlLabel, Checkbox, InputAdornment } from '@mui/material';
import industries from './DataStructure/industries';
import countries from './DataStructure/countries';
import Flag from 'react-world-flags';
import regions from './DataStructure/regions';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import WorkOffSharpIcon from '@mui/icons-material/WorkOffSharp';
import continents from './DataStructure/continents';
import Chip from '@mui/material/Chip';
import { Radio } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Man2SharpIcon from '@mui/icons-material/Man2Sharp';
import Woman2SharpIcon from '@mui/icons-material/Woman2Sharp';
import WcSharpIcon from '@mui/icons-material/WcSharp';
import "./SidePanel.css";
import { HandymanSharp, PublicSharp, PhoneIphoneSharp, BusinessCenterSharp, FilterCenterFocusSharp } from '@mui/icons-material';
import { BiLogoGmail } from "react-icons/bi";
import { Tooltip } from '@mui/material';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { useTheme } from '@mui/material/styles';

interface SidePanelProps {
    selectedIndustry: string[];
    setSelectedIndustry: (industry: string[]) => void;
    selectedJobTitle: string[]; // Updated to accept an array
    setSelectedJobTitle: (jobTitle: string[]) => void; // Updated to accept an array
    excludedJobTitles: string[]; // Updated to accept an array
    setExcludedJobTitles: (jobTitle: string[]) => void; // Updated to accept an array
    selectedCountries: { code: string; name: string; }[];
    setSelectedCountries: (countries: { code: string; name: string; }[]) => void;
    selectedContinent: string[];
    setSelectedContinent: (continent: string[]) => void;
    selectedRegion: string[];
    setSelectedRegion: (region: string[]) => void;
    excludedRegion: string[];
    setExcludedRegion: (region: string[]) => void;
    selectedGender: string;
    setSelectedGender: (gender: string) => void;
    hobbies: string[];
    setHobbies: (hobbies: string[]) => void;
    skills: string[];
    setSkills: (skills: string[]) => void;
    setFoundedBefore: (date: string) => void;
    setFoundedAfter: (date: string) => void;
    foundedBefore: string;
    foundedAfter: string;
    hasPhone: boolean;
    setHasPhone: (value: boolean) => void;
    hasPersonalEmail: boolean;
    setHasPersonalEmail: (value: boolean) => void;
    hasProfessionalEmail: boolean;
    setHasProfessionalEmail: (value: boolean) => void;
    exactJobTitle: boolean;
    setExactJobTitle: (value: boolean) => void;
}

const SidePanel: React.FC<SidePanelProps> = ({
    selectedIndustry,
    setSelectedIndustry,
    selectedJobTitle,
    setSelectedJobTitle,
    excludedJobTitles,
    setExcludedJobTitles,
    selectedCountries,
    setSelectedCountries,
    selectedContinent,
    setSelectedContinent,
    selectedRegion,
    setSelectedRegion,
    excludedRegion,
    setExcludedRegion,
    selectedGender,
    setSelectedGender,
    hobbies,
    setHobbies,
    skills,
    setSkills,
    foundedBefore,
    setFoundedBefore,
    foundedAfter,
    setFoundedAfter,
    hasPhone,
    setHasPhone,
    hasPersonalEmail,
    setHasPersonalEmail,
    hasProfessionalEmail,
    setHasProfessionalEmail,
    exactJobTitle,
    setExactJobTitle
}) => {
    const theme = useTheme();
    const industryRef = useRef();
    const countryRef = useRef();

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1990 + 1 }, (v, i) => 1990 + i);

    return (
        <div style={{ padding: '10px', height: '700px', overflowY: 'auto' }}>
            <Typography variant="h6">Filter Options</Typography>
            <FormControl fullWidth margin="normal">
                <Grid container spacing={2}>
                    {/* Industry */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            fullWidth
                            value={selectedIndustry}
                            onChange={(event, newValue) => setSelectedIndustry(newValue)}
                            options={industries}
                            renderInput={(params) => {
                                params.InputProps.startAdornment = (
                                    <>
                                        <InputAdornment position="start">
                                            <HandymanSharp
                                                sx={{ fontSize: "40px" }}
                                                color={selectedIndustry.length > 0 ? "secondary" : "disabled"}
                                            />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                );
                                return (
                                    <TextField
                                        {...params}
                                        label="Industry"
                                        variant="outlined"
                                        inputRef={industryRef}
                                    />
                                );
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography color="white" style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="primary"
                                        variant="filled"
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                        />
                    </Grid>

                    {/* Countries */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={selectedCountries}
                            fullWidth
                            onChange={(event, newValue) => setSelectedCountries(newValue)}
                            options={countries}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => {
                                params.InputProps.startAdornment = (
                                    <>
                                        <InputAdornment position="start">
                                            <PublicSharp
                                                sx={{ fontSize: "40px" }}
                                                color={selectedCountries.length > 0 ? "secondary" : "disabled"}
                                            />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                );
                                return (
                                    <TextField
                                        {...params}
                                        label="Countries"
                                        variant="outlined"
                                        inputRef={countryRef}
                                    />
                                );
                            }}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Box display="flex" alignItems="center">
                                        <Flag code={option.code} height="24" width="35" />
                                        <span style={{ marginLeft: 8 }}>{option.name}</span>
                                    </Box>
                                </li>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <Flag code={option.code} height="14" width="25" />
                                                <Typography style={{ fontSize: "13px", marginLeft: 8 }}>
                                                    {option.name}
                                                </Typography>
                                            </Box>
                                        }
                                        sx={{ color: "white" }}
                                        variant="filled"
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                        />
                    </Grid>

                    {/* New filter checkboxes */}
                    <Grid item xs={12} sm={12}>
                        <Box height={50} display="flex" alignItems="center" justifyContent="space-around">
                            <CustomTooltip title="Has Phone">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasPhone}
                                            onChange={(e) => setHasPhone(e.target.checked)}
                                            icon={<PhoneIphoneSharp color='disabled' sx={{ fontSize: "35px" }} />}
                                            checkedIcon={<PhoneIphoneSharp color='secondary' sx={{ fontSize: "35px" }} />}
                                        />
                                    }
                                    label=""
                                />
                            </CustomTooltip>
                            <CustomTooltip title="Has Personal Email">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasPersonalEmail}
                                            onChange={(e) => setHasPersonalEmail(e.target.checked)}
                                            icon={<BiLogoGmail style={{ fontSize: "35px", color: theme.palette.action.disabled }} />}
                                            checkedIcon={<BiLogoGmail style={{ fontSize: "35px", color: theme.palette.secondary.main }} />}
                                        />
                                    }
                                    label=""
                                />
                            </CustomTooltip>
                            <CustomTooltip title="Has Professional Email">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasProfessionalEmail}
                                            onChange={(e) => setHasProfessionalEmail(e.target.checked)}
                                            icon={<BusinessCenterSharp color='disabled' sx={{ fontSize: "35px" }} />}
                                            checkedIcon={<BusinessCenterSharp color='secondary' sx={{ fontSize: "35px" }} />}
                                        />
                                    }
                                    label=""
                                />
                            </CustomTooltip>
                        </Box>
                    </Grid>




                    {/* Regions/States */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={selectedRegion}
                            onChange={(event, newValue) => {
                                setSelectedRegion(newValue);
                            }}
                            options={regions} // Make sure this is an array of string
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Regions / States"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography color="white" style={{ fontSize: "13px" }}> {option}</Typography>}

                                        color="primary"
                                        variant="filled"
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                        />
                    </Grid>
                    {/* Exclude Regions/States */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={excludedRegion}
                            onChange={(event, newValue) => {
                                setExcludedRegion(newValue);
                            }}
                            options={regions} // Make sure this is an array of strings
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Exclude Regions / States"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="secondary"
                                        variant="filled"
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                        />
                    </Grid>

                    {/* Job Title */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={selectedJobTitle}
                            onChange={(event, newValue) => setSelectedJobTitle(newValue)}
                            options={[]} // You can provide predefined options here if needed
                            freeSolo // Enable freeSolo
                            autoSelect={true} // Automatically select user's input
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        <span>
                                            <WorkSharpIcon style={{ marginRight: '8px' }} />
                                            Job Title
                                        </span>
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                <CustomTooltip title="Exact job title search (faster)">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={exactJobTitle}
                                                                onChange={(e) => setExactJobTitle(e.target.checked)}
                                                                icon={<FilterCenterFocusSharp color="disabled" />}
                                                                checkedIcon={<FilterCenterFocusSharp color="secondary" />}
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </CustomTooltip>
                                            </>
                                        )
                                    }}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="info"
                                        variant="outlined"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />

                    </Grid>

                    {/* Exclude Job Titles */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={excludedJobTitles}
                            onChange={(event, newValue) => setExcludedJobTitles(newValue)}
                            options={[]} // Leave options empty or provide predefined options here if needed
                            freeSolo // Enable freeSolo
                            autoSelect={true} // Automatically select user's input
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        <span>
                                            <WorkOffSharpIcon style={{ marginRight: '8px' }} />
                                            Exclude Job Titles
                                        </span>
                                    }
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="secondary"
                                        variant="filled"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </Grid>
                    {/* Continent */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={selectedContinent}
                            onChange={(event, newValue) => {
                                setSelectedContinent(newValue);
                            }}
                            options={continents}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Continents"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography color="white" style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="primary" // You can change the color as needed (e.g., primary, secondary, etc.)
                                        variant="filled" // Set to "filled" if you want filled chips
                                        {...getTagProps({ index })}
                                        size="small"

                                    />
                                ))
                            }

                        />
                    </Grid>
                    {/* Gender Selection */}
                    <Grid item xs={12} sm={12}>
                        <Box height={50} display="flex" alignItems="center">
                            <FormControl fullWidth>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender"
                                    value={selectedGender}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setSelectedGender(value);
                                    }}
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} // Adjust justifyContent here
                                >
                                    <div className="radio-label" style={{ margin: '10px' }}> {/* Adjust margin here */}
                                        <FormControlLabel
                                            value=""
                                            control={
                                                <Radio
                                                    icon={<WcSharpIcon color='primary' sx={{ fontSize: "35px" }} />}
                                                    checkedIcon={<WcSharpIcon color='secondary' sx={{ fontSize: "35px" }} />}
                                                />
                                            }
                                            label=""
                                        />
                                        <span className="label-text">Any</span>
                                    </div>
                                    <div className="radio-label" style={{ margin: '10px' }}> {/* Adjust margin here */}
                                        <FormControlLabel
                                            value="male"
                                            control={
                                                <Radio
                                                    icon={<Man2SharpIcon color='primary' sx={{ fontSize: "35px" }} />}
                                                    checkedIcon={<Man2SharpIcon color='secondary' sx={{ fontSize: "35px" }} />}
                                                />
                                            }
                                            label=""
                                        />
                                        <span className="label-text">Male</span>
                                    </div>
                                    <div className="radio-label" style={{ margin: '10px' }}> {/* Adjust margin here */}
                                        <FormControlLabel
                                            value="female"
                                            control={
                                                <Radio
                                                    icon={<Woman2SharpIcon color='primary' sx={{ fontSize: "35px" }} />}
                                                    checkedIcon={<Woman2SharpIcon color='secondary' sx={{ fontSize: "35px" }} />}
                                                />
                                            }
                                            label=""
                                        />
                                        <span className="label-text">Female</span>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>

                    {/* Hobbies */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={hobbies}
                            options={[]} // Leave options empty or provide predefined options here if needed
                            onChange={(event, newValue) => setHobbies(newValue)}
                            freeSolo // Enable free text entry
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Hobbies"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="primary"
                                        variant="outlined"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </Grid>

                    {/* Skills */}
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            multiple
                            value={skills}
                            options={[]} // Leave options empty or provide predefined options here if needed
                            onChange={(event, newValue) => setSkills(newValue)}
                            freeSolo // Enable free text entry
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Skills"
                                    variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={<Typography style={{ fontSize: "13px" }}> {option}</Typography>}
                                        color="primary"
                                        variant="outlined"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </Grid>

                    {/* Founded Before */}
                    <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth>
                            <InputLabel id="founded-before-label">Founded Before</InputLabel>
                            <Select
                                labelId="founded-before-label"
                                id="founded-before-select"
                                value={foundedBefore}
                                onChange={(e) => setFoundedBefore(e.target.value)}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {foundedBefore && (
                            <IconButton
                                aria-label="clear founded before"
                                onClick={() => setFoundedBefore('')}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>

                    {/* Founded After */}
                    <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth>
                            <InputLabel id="founded-after-label">Founded After</InputLabel>
                            <Select
                                labelId="founded-after-label"
                                id="founded-after-select"
                                value={foundedAfter}
                                onChange={(e) => setFoundedAfter(e.target.value)}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {foundedAfter && (
                            <IconButton
                                aria-label="clear founded after"
                                onClick={() => setFoundedAfter('')}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>



                </Grid>
            </FormControl>
        </div >
    );
}

export default SidePanel;
