// src\app\pages\5-Inbox\mail\components\SMSConversationDisplay.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box } from '@mui/material';
import SMSForm from './smsSendingForm';

interface Message {
    id: string;
    from: string;
    to: string;
    body: string;
    type: 'incoming' | 'outgoing';
    receivedAt: string;
    deliveryStatus: string;
}

interface SMSConversationDisplayProps {
    from: string;
    conversationId: string;
}

const SMSConversationDisplay: React.FC<SMSConversationDisplayProps> = ({ from, conversationId }) => {
    console.log("conversationId in displaysms", conversationId)

    const [messages, setMessages] = useState<Message[]>([]);

    const [toNumber, setToNumber] = useState('');


    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/conversation/${conversationId}/messages`);
                setMessages(response.data.messages);
            } catch (error) {
                console.error('Failed to fetch messages:', error);
            }
        };

        fetchMessages();
    }, [conversationId]);



    return (
        <>
            <div style={{ padding: '10px' }}>
                {messages.map((message) => (
                    <Box
                        key={message.id}
                        sx={{
                            backgroundColor: message.type === 'incoming' ? '#793A99' : '#FAAF00',
                            color: 'white',
                            padding: '10px',
                            borderRadius: '10px',
                            maxWidth: '60%',
                            margin: message.type === 'incoming' ? '10px 0 10px auto' : '10px auto 10px 0',
                            alignSelf: message.type === 'incoming' ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <Typography variant="body2">{message.body}</Typography>


                    </Box>

                ))}
            </div>
            <SMSForm fromNumber={from} toNumber={
                messages.length > 0
                    ? messages[messages.length - 1].from === from
                        ? messages[messages.length - 1].to
                        : messages[messages.length - 1].from
                    : ''
            }
            />
        </>
    );
};

export default SMSConversationDisplay;
