import { useAuth } from '@/src/app/modules/auth';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import MoreTimeSharpIcon from '@mui/icons-material/MoreTimeSharp';
import SaveSharp from '@mui/icons-material/SaveSharp';
import { Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const dayOfWeekMapping = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
};

interface CustomizedDividersProps {
    selectedCampaignId: string | null;
}

export default function CustomizedDividers({ selectedCampaignId }: CustomizedDividersProps) {
    const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
    const [fromTimes, setFromTimes] = React.useState({}); // 'From' times for each day
    const [toTimes, setToTimes] = React.useState({}); // 'To' times for each day
    const [sharedFromTime, setSharedFromTime] = useState<Dayjs | null>(null);
    const [sharedToTime, setSharedToTime] = useState<Dayjs | null>(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false); // Modal visibility state
    const [schedule, setSchedule] = React.useState({});
    const [campaignData, setCampaignData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedTimezone, setSelectedTimezone] = useState({ value: '', label: '' });
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchCampaignData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}`);
                setCampaignData(response.data);
                updateToggleButtonsAndTimes(response.data.schedule);
                setSelectedTimezone({ value: response.data.timezone, label: response.data.timezone }); // Set timezone
                setLoading(false); // Set loading to false after fetching data
            } catch (error) {
                console.error('Error fetching campaign data:', error);
                Swal.fire({   // Using SweetAlert for better user feedback
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong while fetching campaign data!',
                });
                setLoading(false); // Set loading to false even if there's an error
            }
        };

        if (selectedCampaignId) {
            fetchCampaignData();
        }
    }, [selectedCampaignId]);

    const updateToggleButtonsAndTimes = (scheduleData) => {
        if (!scheduleData || !currentUser) return; // Early return if data or currentUser is not valid
        const days = Object.keys(scheduleData);
        setSelectedDays(days);

        const newFromTimes = {};
        const newToTimes = {};
        const newSchedule = {};

        days.forEach(day => {
            // No timezone conversion needed
            newFromTimes[day] = dayjs(`${dayjs().format('YYYY-MM-DD')}T${scheduleData[day].start}:00`);
            newToTimes[day] = dayjs(`${dayjs().format('YYYY-MM-DD')}T${scheduleData[day].end}:00`);
            newSchedule[day] = { start: scheduleData[day].start, end: scheduleData[day].end };
        });

        setFromTimes(newFromTimes);
        setToTimes(newToTimes);
        setSchedule(newSchedule); // Update schedule state here
    };

    const handleDayToggle = (day: string) => {
        setSelectedDays((prevSelectedDays) => {
            const newSelectedDays = prevSelectedDays.includes(day)
                ? prevSelectedDays.filter((d) => d !== day)
                : [...prevSelectedDays, day];

            // Update schedule state for all selected days
            const updatedSchedule = { ...schedule };
            newSelectedDays.forEach(selectedDay => {
                updatedSchedule[selectedDay] = updatedSchedule[selectedDay];
            });

            if (!newSelectedDays.includes(day)) {
                // Remove the day from the schedule if it's unselected
                delete updatedSchedule[day];
            }

            setSchedule(updatedSchedule);
            return newSelectedDays;
        });
    };

    const handleFromTimeChange = (day, newTime) => {
        if (loading || !selectedDays.includes(day) || !currentUser) return;
        setFromTimes({ ...fromTimes, [day]: newTime });

        // Update the schedule for the selected day
        setSchedule(prevSchedule => ({
            ...prevSchedule,
            [day]: {
                ...prevSchedule[day],
                start: newTime ? newTime.format('HH:mm') : ''
            }
        }));
    };

    const handleToTimeChange = (day, newTime) => {
        if (loading || !selectedDays.includes(day) || !currentUser) return;
        setToTimes({ ...toTimes, [day]: newTime });

        // Update the schedule for the selected day
        setSchedule(prevSchedule => ({
            ...prevSchedule,
            [day]: {
                ...prevSchedule[day],
                end: newTime ? newTime.format('HH:mm') : ''
            }
        }));
    };

    const handleSubmitSchedule = async () => {
        if (!selectedCampaignId) {
            Swal.fire({
                icon: 'warning',
                title: 'No Campaign Selected',
                text: 'Please select a campaign to update the schedule.',
            });
            return;
        }

        if (Object.keys(schedule).length === 0) {
            Swal.fire({
                icon: 'info',
                title: 'Empty Schedule',
                text: 'Please select days and times before updating the schedule.',
            });
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/update-schedule`, { schedule }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Schedule Updated',
                    text: 'The schedule has been successfully updated.',
                });
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            // Type-checking for the error
            if (error instanceof Error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: `There was a problem updating the schedule: ${error.message}`,
                });
            } else {
                // Handle cases where the error is not an instance of Error
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: 'There was an unknown problem updating the schedule.',
                });
            }
        }
    };

    const applySharedTimesToAll = () => {
        if (loading || !currentUser || !sharedFromTime || !sharedToTime) return; // Early return if currentUser or shared times are not valid

        const updatedFromTimes = {};
        const updatedToTimes = {};

        selectedDays.forEach(day => {
            updatedFromTimes[day] = sharedFromTime;
            updatedToTimes[day] = sharedToTime;
        });

        setFromTimes(updatedFromTimes);
        setToTimes(updatedToTimes);

        const updatedSchedule = {};
        selectedDays.forEach(day => {
            updatedSchedule[day] = {
                start: updatedFromTimes[day].format('HH:mm'),
                end: updatedToTimes[day].format('HH:mm'),
            };
        });
        setSchedule(updatedSchedule);
        setIsModalOpen(false);
    };

    const handleClearAllValues = () => {
        // Logic to clear values
        const clearedFromTimes = {};
        const clearedToTimes = {};

        selectedDays.forEach((day) => {
            clearedFromTimes[day] = null; // Clear 'From' time for the selected day
            clearedToTimes[day] = null; // Clear 'To' time for the selected day
        });

        setFromTimes({ ...fromTimes, ...clearedFromTimes });
        setToTimes({ ...toTimes, ...clearedToTimes });

        setIsModalOpen(false); // Close the modal after clearing values
    };

    const sortDays = (days) => {
        return days.sort((a, b) => dayOfWeekMapping[a] - dayOfWeekMapping[b]);
    };

    const AnimatedButton = animated(Button);

    const showButtonSpring = useSpring({
        opacity: isModalOpen ? 0 : 1,
        transform: isModalOpen ? 'scale(0.8)' : 'scale(1)',
        config: { tension: 250, friction: 20 },
    });

    const hideButtonSpring = useSpring({
        opacity: isModalOpen ? 1 : 0,
        transform: isModalOpen ? 'scale(1)' : 'scale(0.8)',
        config: { tension: 250, friction: 20 },
    });

    const timezoneOptions = moment.tz.names().map((zone) => ({
        value: zone,
        label: `${zone} (GMT${moment.tz(zone).format('Z')}) ${moment.tz(zone).zoneAbbr()}`,
    }));

    const handleTimezoneChange = async (event, newValue) => {
        const timezone = timezoneOptions.find(option => option.label === newValue);
        if (timezone) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/update-timezone`, {
                    timezone: timezone.value,
                });

                if (response.status === 200) {
                    setSelectedTimezone(timezone);
                    Swal.fire({
                        icon: 'success',
                        title: 'Timezone Updated',
                        text: 'The timezone has been successfully updated.',
                    });
                } else {
                    throw new Error(`Unexpected response status: ${response.status}`);
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: `There was a problem updating the timezone: ${error.message}`,
                });
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Or any loading spinner/component
    }

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: "20px" }}>
                <StyledToggleButtonGroup
                    size="small"
                    value={selectedDays}
                    onChange={(_, newSelectedDays) => setSelectedDays(newSelectedDays)}
                    aria-label="Select Days of the Week"
                >
                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
                        <ToggleButton
                            key={day}
                            value={day}
                            aria-label={day}
                            onClick={() => handleDayToggle(day)}
                            sx={{ fontSize: "30px", paddingRight: "20px", paddingLeft: "20px", color: "#793A99" }}
                            color='info'
                        >
                            {day.substring(0, 3)}
                        </ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
                <animated.div style={showButtonSpring}>
                    <Tooltip title="Set Time for Selected Days">
                        <IconButton
                            onClick={() => setIsModalOpen(true)}
                        >
                            <MoreTimeSharpIcon
                                color='info'
                                sx={{ fontSize: "35px" }}
                            />
                        </IconButton>
                    </Tooltip>
                </animated.div>

                {/* New IconButton to clear values */}
                <animated.div style={showButtonSpring}>
                    <Tooltip title="Clear All Values">
                        <IconButton
                            onClick={handleClearAllValues}
                        >
                            <BackspaceRoundedIcon
                                sx={{ fontSize: "20px" }}
                            />
                        </IconButton>
                    </Tooltip>
                </animated.div>
            </Grid>

            <Grid item xs={12} md={6}>
                <Autocomplete
                    value={selectedTimezone.label}
                    onChange={handleTimezoneChange}
                    options={timezoneOptions.map(option => option.label)}
                    renderInput={params => <TextField {...params} label="Timezone" />}
                    fullWidth
                    disableClearable
                />
            </Grid>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Paper style={{ padding: 50 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={4} alignItems="center" justifyContent="center">
                            <Grid item xs={5}>
                                <TimePicker
                                    label="Shared From Time"
                                    value={sharedFromTime}
                                    onChange={setSharedFromTime}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TimePicker
                                    label="Shared To Time"
                                    value={sharedToTime}
                                    onChange={setSharedToTime}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <IconButton color="primary" onClick={applySharedTimesToAll}>
                                    <SaveSharp
                                        style={{ fontSize: "45px" }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Paper>
            </Modal>

            {selectedDays.sort((a, b) => dayOfWeekMapping[a] - dayOfWeekMapping[b]).map((day) => (
                <Grid item xs={12} md={6} key={`${day}-timepickers`}>
                    <Paper elevation={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                                <TimePicker
                                    label={`From Time for ${day}`}
                                    value={fromTimes[day] || null}
                                    onChange={(newTime) => handleFromTimeChange(day, newTime)}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                                <TimePicker
                                    label={`To Time for ${day}`}
                                    value={toTimes[day] || null}
                                    onChange={(newTime) => handleToTimeChange(day, newTime)}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </div>
                        </LocalizationProvider>
                    </Paper>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Box textAlign="center">
                    <Button variant="contained" color="primary" onClick={handleSubmitSchedule}>
                        Update Schedule
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
