import { useAuth } from '@/src/app/modules/auth';
import { Avatar, AvatarFallback, AvatarImage } from '@/src/ui/ui/avatar';
import { Separator } from '@/src/ui/ui/separator';
import { Send } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';

import EmailIframe from './EmailHTMLIframe';

dayjs.extend(utc);
dayjs.extend(timezone);

interface EmailMessage {
    html: string;
    text: string;
}

interface ItemProps {
    email: string;
    messageId: string;
    name: string;
    subject: string;
    date: string;
}

export const MailContent: React.FC<{ item: ItemProps; from: any; }> = ({ item, from }) => {
    const [reply, setReply] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [sendType, setSendType] = useState<'immediate' | 'scheduled'>('immediate');
    const [delayInSeconds, setDelayInSeconds] = useState(0);
    const [emailContent, setEmailContent] = useState<EmailMessage | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchEmailContent = async () => {
            setLoading(true);
            setError(null);
            setEmailContent(null);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/email-thread`, {
                    //@ts-ignore
                    emailAccountId: item.toEmailAccountId, // Replace with actual email account field
                    messageId: item.messageId, // Replace with actual message ID field
                });

                setEmailContent(response.data);
            } catch (error) {
                console.error('Failed to fetch email content:', error);
                setError('Failed to fetch email content');
            }

            setLoading(false);
        };

        if (item) {
            fetchEmailContent();
        }
    }, [item]);

    const handleSendEmail = async () => {
        setIsSending(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/send-manual-email`, {
                to: item.email,
                subject: `Re: ${item.subject}`,
                body: reply,
                emailAccountId: from, // Replace with your sender email
                options: {},
                sendType: sendType,
                delayInSeconds: delayInSeconds.toString(),
            });
            console.log('Email sent successfully:', response.data);
        } catch (error) {
            console.error('Failed to send email:', error);
        }
        setIsSending(false);
    };

    if (!item) {
        return <Typography className="p-8 text-center text-muted-foreground">No message selected</Typography>;
    }

    if (loading) {
        return (
            <Box className="p-8 text-center">
                <CircularProgress />
                <Typography className="text-muted-foreground">Loading...</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box className="p-8">
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (!emailContent) {
        return <Typography className="p-8 text-center text-muted-foreground">No content found</Typography>;
    }

    return (
        <>
            <Separator />
            <Box className="flex flex-1 flex-col p-4">
                <Avatar>
                    <AvatarImage alt={item.name} />
                    <AvatarFallback>{item.name && item.name.length > 0 ? item.name[0] : '?'}</AvatarFallback>
                </Avatar>
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="body1">{item.subject}</Typography>
                <Typography variant="body2">
                    <span>Reply-To: {item.email}</span>
                </Typography>
                {item.date && currentUser && (
                    <Typography variant="body2">
                        {dayjs(item.date).tz(currentUser.timezone).format('MMMM D, YYYY h:mm A')}
                    </Typography>
                )}
                <Box className="mt-2">
                    <EmailIframe html={emailContent.html} />
                </Box>
            </Box>
            <Separator />
            <Box className="p-0 sticky bottom-0">
                <Paper className="p-4">
                    <TextField
                        id="outlined-multiline-static"
                        label="Reply"
                        multiline
                        fullWidth
                        minRows={1}
                        maxRows={4}
                        placeholder={`Reply to ${item.name}...`}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        variant="outlined"
                    />
                    {reply && (
                        <Box className="flex justify-between items-center mt-2">
                            <Select
                                value={sendType}
                                onChange={(e) => setSendType(e.target.value as 'immediate' | 'scheduled')}
                                variant="outlined"
                            >
                                <MenuItem value="immediate">Send Immediately</MenuItem>
                                <MenuItem value="scheduled">Schedule</MenuItem>
                            </Select>
                            {sendType === 'scheduled' && (
                                <TextField
                                    type="number"
                                    label="Delay in Seconds"
                                    value={delayInSeconds}
                                    onChange={(e) => setDelayInSeconds(Number(e.target.value))}
                                    variant="outlined"
                                    style={{ marginLeft: '1rem' }}
                                />
                            )}
                            <Button
                                onClick={handleSendEmail}
                                disabled={isSending}
                                variant="contained"
                                color="primary"
                                endIcon={isSending ? <CircularProgress size={20} /> : <Send />}
                                style={{ marginLeft: '1rem' }}
                            >
                                {isSending ? 'Sending...' : 'Send'}
                            </Button>
                        </Box>
                    )}
                </Paper>
            </Box>
        </>
    );
};
