// src\app\pages\5-Inbox\mail\components\account-switcher.tsx
import { Icon } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

interface AccountSwitcherProps {
  isCollapsed: boolean;
  accounts: {
    label: string;
    email: string;
    icon: React.ReactNode;
    emailAccountId: string;
    type: string;
  }[];

  onAccountSelected: (accountId: string, accountType: string) => void;
  selectedAccountId: string
}

export function AccountSwitcher({ isCollapsed, accounts, onAccountSelected, selectedAccountId }: AccountSwitcherProps) {


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;

    // Default to "all" if no account is found
    let accountType = "all";
    let accountId = "all";

    // If "all" is not selected, find the account and use its details
    if (selectedValue !== "all") {
      const account = accounts.find(account => account.emailAccountId === selectedValue);
      if (account) {
        accountType = account.type;
        accountId = account.emailAccountId;
      }
    }

    // Update the selected account using the handler
    onAccountSelected(accountId, accountType);
  };




  console.log("selectedaccounts", selectedAccountId)

  return (
    <FormControl variant="outlined" fullWidth>
      {!isCollapsed && <InputLabel id="account-switcher-label">Account</InputLabel>}
      <Select
        labelId="account-switcher-label"
        id="account-switcher"
        value={selectedAccountId}
        variant='filled'
        //@ts-ignore
        onChange={handleChange}
        label={!isCollapsed ? "Account" : undefined}
        displayEmpty
        autoWidth
        //@ts-ignore
        IconComponent={() => (isCollapsed ? null : undefined)} // Optionally hide the dropdown icon when collapsed
      >
        <MenuItem value="all">All Accounts</MenuItem>

        {accounts.map((account) => (
          <MenuItem key={account.emailAccountId} value={account.emailAccountId}>
            {account.icon && (
              <Icon style={{ marginRight: '10px' }}>
                {account.icon} {/* Make sure your icons are compatible */}
              </Icon>
            )}
            {!isCollapsed && account.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
